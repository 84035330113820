const Account = {
  login: `${process.env.REACT_APP_MS_CHAT}`,
  signOut: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/auth/logout`,
  sendCode: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/enviar-codigo-login`,
  register: `${process.env.REACT_APP_MS_CHAT}`,
  forgotPassword: `${process.env.REACT_APP_MS_CHAT}`,
  disableEmployee: `${process.env.REACT_APP_MS_FUNCIONARIO}/v1/funcionarios/desativar`,
  enableUser: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/ativar`,
  userLogin: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/auth/login`,
  putUserProfile: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/atualizar-perfil`,
  getUserByIdClienteAutorizador: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/por-idCLienteAutorizador/`,
  validateUserPassword: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/validar-senha-usuario`,
  postDeactivateEmployeeProfile: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/desativar-perfil-funcionario/PARAM_CPF`,
  postActivateEmployeeProfile: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/ativar-perfil-funcionario/PARAM_CPF`,
  postBlockUser: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/bloquear-usuario/PARAM_ID`,
  postUnblockUser: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/desbloquear-usuario/PARAM_ID`,
};

// REACT_APP_MS_AUTORIZADOR
const Consult = {
  searchBy: `${process.env.REACT_APP_MS_CHAT}`,
  getUsers: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios`,
  getUserDetail: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/detalhes/PARAM_CPF`,

  getUserAppAccessHistory: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/lista-historico-acesso/PARAM_ID_USUARIO`,
  getAllProfiles: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/retorna-perfis-disponiveis`,

  searchEmployeeByCpf: `${process.env.REACT_APP_MS_FUNCIONARIO}/v1/funcionarios/busca-porCpf/PARAM_CPF`,
  searchEmployeeByName: `${process.env.REACT_APP_MS_FUNCIONARIO}/v1/funcionarios/busca-porNome`,
  searchCompany: `${process.env.REACT_APP_MS_EMPRESA}/v1/empresas`,
  searchProposalNameCPFFilter: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/retornar-proposta-nome-cpf`,
  searchProposalReanaliseNameCPF: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/retornar-proposta-reanalise`,
  searchProposalSPC: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/dados-proposta-spc/PARAM_ID_PROPOSTA`,
  searchUserDocuments: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/obter-documentos-identificacao/`,
  searchUserDocumentsCPF: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/obter-documentos-identificacao-cpf/PARAM_CPF`,
  searchRemakeProposalRanson: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/motivos-refazer`,
  searchRemakeProposal: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/gravar-motivo-refazer-proposta/PARAM_ID_PROPOSTA`,
  searchProposalPaginationNameCPF: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/proposta-mesa-credito-paginacao`,
  searchCardDeliveryTerm: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/obter-documentos-termo-entrega/PARAM_CPF`,

  // Transaction Consult
  walletPaymentList: `${process.env.REACT_APP_MS_PAGAMENTOS}/v1/pagamentos/listar-pagamentos-carteira/PARAM_CPF?pagina=PARAM_PAGINA&qtdRegistros=PARAM_QTD_REGISTROS`,
  walletPaymentDetail: `${process.env.REACT_APP_MS_PAGAMENTOS}/v1/pagamentos/detalhes-pagamento-carteira/PARAM_ID`,
  walletPaymentCancel: `${process.env.REACT_APP_MS_PAGAMENTOS}/v1/pagamentos/cartao-credito/PARAM_NSU`,

  // ReportSearchPayload
  searchDispute: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/listar-disputas`,
  searchDisputeDetail: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-disputa/`,
  searchProposalReport: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/obter-relatorio-proposta`,

  // Reactivate - Reativar
  searchReactivate: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/reativacoes/listar-pendentes`,
  detailUserReactivate: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/reativacoes/reativacao-porId/PARAM_ID_REACTIVATE`,

  searchLimitSolicitation: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/cliente-reanalise`,
  searchTransaction: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/lista-extratos/PARAM_QUANTITY`,
  detailTransaction: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/detalhe-transacao`,
  transactionInstallments: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/parcelas-transacao`,

  searchUser: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-ativo`,
  searchUserProduct: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-ativo-produto`,
  detailUser: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/consultar-cliente-backoffice/PARAM_CPF`,
  detailUserWallet: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/consultar-cliente-carteira-backoffice/PARAM_CPF`,
  updateUser: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/alterar-cliente`,
  updateClient: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/update-cliente`,
  getUserLimitHistory: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/aumento-limite/consultar-lista-paginada/PARAM_CPF`,
  detailInvoice: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/extrato/fatura-pagamentos?idFatura=PARAM_ID_FATURA&limitePorPagina=PARAM_LIMIT_PAGE&pagina=PARAM_PAGE`,
  getUserDaysDelay: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/cliente/dias-atraso?numeroCartao=PARAM_NUMERO_CARTAO`,

  searchEmailForgot: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/usuario-porCpf`,
  searchReasonsBlock: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/listar-motivos-bloqueio/PARAM_CC_NUMBER`,
  searchUserListInvoice: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-lista-faturas`,
  searchInvoice: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/gerar-fatura/PARAM_CPF/PARAM_MES/PARAM_ANO`,
  sendInvoiceEmail: `${process.env.REACT_APP_MS_EMAIL}/v1/email/email-fatura/PARAM_CPF/PARAM_MES/PARAM_ANO/PARAM_EMAIL`,
  emailLog: `${process.env.REACT_APP_MS_EMAIL}/v1/email/log-envio-email/PARAM_CPF`,
  toAssign: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/atribuir/PARAM_ID_PROPOSTA`,
  toUnassign: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/desatribuir/PARAM_ID_PROPOSTA`,
  getCardHistory: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/historico-cartoes?cpf=PARAM_CPF`,

  userContract: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/noauth/proposta-assinatura?id=PARAM_ID_PROPOSTA`,
  userIncome: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/noauth/proposta-assinatura?id=PARAM_ID_PROPOSTA`,
  userResidence: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/noauth/proposta-assinatura?id=PARAM_ID_PROPOSTA`,

  conciliarArquivo: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/conciliar-arquivo-retorno`,
  searchTotalBalanceInvoice: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/buscar-saldo-total-cobranca/PARAM_CPF`,

  searchClientCpf: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-cpf/PARAM_CPF`,
  cancelCard: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/cancelar-cliente/PARAM_TIPO`,

  getInvoice: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/consultar-fatura-backoffice/PARAM_ID_FATURA`,

  getWithDrawLimit: `${process.env.REACT_APP_MS_COBRANCA}/v1/box-base-clientes/limite-disponivel-saque?cpf=PARAM_CPF`,
  getWithDrawLimitHistory: `${process.env.REACT_APP_MS_COBRANCA}/v1/box-base-clientes/historico-alteracao-limite-saque?cpf=PARAM_CPF`,
  getTotalLimit: `${process.env.REACT_APP_MS_COBRANCA}/v1/box-base-clientes/limite-compra-saque?cpf=PARAM_CPF`,
  getCustomerBase: `${process.env.REACT_APP_MS_COBRANCA}/v1/box-base-clientes?cpf=PARAM_CPF`,
  getCampaignLimitIncrease: `${process.env.REACT_APP_MS_COBRANCA}/v1/box-base-clientes/parametro-ativo-aumento-limite-campanha?cpf=PARAM_CPF`,
  getTotemPaymentDetails: `${process.env.REACT_APP_MS_PAGAMENTOS}/v1/pagamentos/detalhes-transacao-pagamento-fatura-totem?idPagamentoBackoffice=PARAM_ID`,
  getTotemPaymentComprovant: `${process.env.REACT_APP_MS_PAGAMENTOS}/v1/pagamentos/comprovante-pagamento-fatura-totem?idPagamentoBackoffice=PARAM_ID`,
  getConsultZeussBalance: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/consultar-saldo-zeuss/PARAM_CPF`,
  getConsultZeussPhone: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/obter-celular-zeuss/PARAM_CPF`,
  postValidatePhoneZeuss: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/validar-celular-zeuss/PARAM_CPF?celularZeuss=PARAM_PHONE`,

  getAnfitriao: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao`,
  getAnfitriaoDetails: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao/detalhes/PARAM_ID`,
  getValidateAnfitriaoCpf: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao/validar-cpf/PARAM_CPF`,
  postCreateAnfitriao: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao`,
  putChangeAnfitriaoData: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao/alterar-anfitriao?idAnfitriao=PARAM_ID`,
  putChangeAnfitriaoStatus: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao/alterar-status?idAnfitriao=PARAM_ID&status=PARAM_STATUS`,
  putSendEmailAnfitriao: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao/enviar-email-anfitriao/PARAM_ID`,
  getSearchHistoricalBenefitProposal: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/buscar-historico-beneficios-proposta/PARAM_CPF`,

  getPaymentRules: `${process.env.REACT_APP_MS_CARTEIRA}/v1/carteiras/regras-pagamento-transacao-digital`,

  getAddressByCep: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/cep/PARAM_CEP`,
};

const Card = {
  block: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/bloquear-cartao-backoffice`,
  getToken: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/noauth/token-cartao`,
  listOfReasonsForBlocking: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/motivos-bloqueio/PARAM_ORIGEM`,
  getThemeListCreditCard: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/listar-categorias-cartao`,
  getClientCurrentCategory: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/listar-categorias-cartao-atual/PARAM_CPF`,
  getClientCategoryNotHave: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/listar-nao-categorias-cartao-atual/PARAM_CPF`,
};

const Register = {
  operator: `${process.env.REACT_APP_MS_FUNCIONARIO}/v1/funcionarios`,
  proposal: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/complementar-proposta`,
  proposalInitial: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/proposta-inicial`,
  proposalReference: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/salvar-contato`,
  proposalContacts: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/listar-contatos/PARAM_ID_PROPOSTA`,
  proposalRefuse: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/recusar-proposta/PARAM_ID_PROPOSTA`,
  proposalApprove: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/aprovar-mesa-credito`,
  proposalKeepAnalysis: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/manter-analise-proposta/PARAM_ID_PROPOSTA`,
  proposalMotReanaliseApro: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/aprovar-analise-limite`,
  proposalMotReanaliseRepro: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/reprovar-analise-limite`,
  postWebsocketProposal: `${process.env.REACT_APP_WEBSOCKET_EVENT}/v1/websockets/ficha-proposta/aprovacao-proposta/PARAM_ID_PROPOSTA`,

  sendEmailForgot: `${process.env.REACT_APP_MS_EMAIL}/v1/email/noauth/link-senha-email-backoffice`,
  resetPasswordClient: `${process.env.REACT_APP_MS_EMAIL}/v1/email/noauth/enviar-link-redefinicao-senha`,
  forgotNewPassword: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/confirmar-alterar-senha-backoffice`,
  duplicateCard: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/reemissao`,
  postSetTransaction: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/transacao-solicitada/incluir`,
  reactivateDecisionApro: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/reativacoes/aprovar-reativacao/PARAM_ID_REATIVACAO`,
  reactivateDecisionRepro: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/reativacoes/reprovar-reativacao/PARAM_ID_REATIVACAO`,
  sendReactivateDecision: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/reativacoes/reativar-cliente`,
  cancelProposal: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/cancelar-proposta/PARAM_ID_PROPOSTA`,
};

const Create = {
  dispute: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/iniciar-disputa`,
};

const Update = {
  employee: `${process.env.REACT_APP_MS_FUNCIONARIO}/v1/funcionarios`,
  proposal: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/alterar-dados-proposta`,
  invoiceExpiration: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/alterar-vencimento-fatura`,
  endDispute: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/finalizar-disputa/`,
  sendDecision: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/reanalisar-limite/PARAM_CPF`,
  updateAutomaticLimit: `${process.env.REACT_APP_MS_COBRANCA}/v1/box-base-clientes/ativa-desativa-aumento-limite-campanha`,
  // Teste pipline
};

const Files = {
  upload: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/upload-arquivo`,
  uploadUserDocuments: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/salvar-identificacao-cliente`,
  uploadHistoryPerDay: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/relatorio-arquivos-processados-por-dias?dias=PARAM_DAYS`,
  uploadHistory: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/relatorio-arquivos-processados`,
  uploadCardDeliveryTerm: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/salvar-termo-entrega`,
  downloadProposalReports: `${process.env.REACT_APP_MS_PROPOSTA}/v1/box-dashboard/export-csv/obter-relatorio-propostas`,
  getDocumentTypes: `${process.env.REACT_APP_MS_DOCUMENTOS}/v1/documentos/buscar-tipos-documentos`,
  postProposalDocuments: `${process.env.REACT_APP_MS_DOCUMENTOS}/v1/documentos/upload-documento`,
  getProposalDocuments: `${process.env.REACT_APP_MS_DOCUMENTOS}/v1/documentos/buscar-documentos/PARAM_CPF`,
  deleteProposalDocument: `${process.env.REACT_APP_MS_DOCUMENTOS}/v1/documentos/deletar-documentos/PARAM_CPF/PARAM_FILENAME`,
  postGetDoc64: `${process.env.REACT_APP_MS_DOCUMENTOS}/v1/documentos/buscar-documento`,
  getPlatinumTerms: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/contrato-platinum-assinado/PARAM_ID_PROPOSTA`,
  getCancelInactivityReport: `${process.env.REACT_APP_MS_DOCUMENTOS}/v1/box-base-clientes/export-csv/relatorio-fila-clientes-inativos`,
  getPrevisionCancelInactivityReport: `${process.env.REACT_APP_MS_DOCUMENTOS}/v1/box-base-clientes/export-csv/relatorio-previsao-clientes-inativos`,
};

const Report = {
  getOperators: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/obter-operadores`,
  getReport: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-gerencial`,
  getReportApproved: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-gerencial-aprovadas`,
  getReportProposalP1: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/propostas-p1`,
  getReportDelay: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/obter-relatorio-faturas-atraso-paginacao`,
  getReportIOF: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/obter-relatorio-iof`,
  getReportTransactions: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-gerencial-transacoes`,
  getReportInvoiceIssued: `${process.env.REACT_APP_MS_FECHAMENTO}/v1/fechamentos/obter-relatorio-faturas-emitidas-paginacao`,
  getReportUnlockedCards: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cartoes-data-ativacao`,
  getReportSales: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-vendas`,
  getReportOdonto: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/obter-relatorio-vendas-teu-card-odonto`,
  getReportCuida: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/obter-relatorio-cuida`,
  getReportCancelCuida: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/obter-relatorio-cuida-cancelamentos`,
  getReportBillingOdonto: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/relatorio-faturamento-teucard-odonto`,
  getReportInactiveCards: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cartoes-inativos`,
  getReportProposalsRegistered: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/propostas-cadastradas-paginacao`,
  getReportInvoicesReceived: `${process.env.REACT_APP_MS_FECHAMENTO}/v1/fechamentos/obter-relatorio-faturas-recebidas-paginacao`,
  getReportCharges: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/cobmais`,
  getReportFinancialIncome: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/obter-relatorio-vendas`,
  getReportRecipeAgreement: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/reativacoes/receita-acordo-paginacao`,
  getReportPositiveUser: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/cliente-positivado`,
  getReportOverdue: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-cobranca/PARAM_DATE`,
  getReportFileChargeDownload: `${process.env.REACT_APP_MS_RELATORIO}/v1/cobrancas/noauth/export-xls-cobranca?diaVencimento=PARAM_DAY`,
  getReportFileActiveLimits: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/limitesAtivos/export-csv/PARAM_FILTRO/PARAM_LIMITE`,
  getReportInvoiceDelayDownload: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/noauth/export-csv`,
  getReportChangedLimit: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/aumento-limite/consultar-lista-paginada`,
  getReportFutureInvoices: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-faturas-futuras-paginacao`,
  getReportFileDownloadPositive: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/positivados/export-csv/PARAM_STATUS/PARAM_DIAS`,
  getReportReactivatedCustomers: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-cliente-reativados-paginacao-order`,
  getReportFileCustomerBase: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/export-csv/obter-relatorio-clientes/PARAM_STATUS`,
  getReportDisapprove: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-gerencial-reprovadas`,
  getReportSignedContracts: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-contratos-assinados`,
  getReportCancelOdonto: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/obter-relatorio-cancelamento-odonto`,
  getInsuranceReport: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/obter-relatorio-seguros`,
  getReportCancelProtection: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/obter-relatorio-cancelamento-seguros`,
  getReportAdesoes: `${process.env.REACT_APP_MS_PRODUTO}/v1/produtos/relatorio-adesoes-carteira`,
  getReportVendas: `${process.env.REACT_APP_MS_EVENTOS}/v1/eventos/relatorio-leads-carteira`,
  getReportWalletTransactions: `${process.env.REACT_APP_MS_PAGAMENTOS}/v1/pagamentos/relatorio-transacoes-carteira-digital`,
  getReportLoanSeeker: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-relatorio-propostas`,
  getReportWithdrawBilling: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-emprestimos-saque-faturados-paginado`,
  putRemoveBlackList: `${process.env.REACT_APP_MS_PAGAMENTOS}/v1/carteiras/blacklist/remover-black-list?idCartao=PARAM_ID&idOperador=PARAM_OP`,
  getReportCashBackZeuss: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/relatorio-cashback-zeuss`,
  getReportManuallyInvoice: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/transacao-solicitada/relatorio-transacoes-aprovadas`,
  getReportAnfitriao: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao/relatorio-anfitrioes-paginado`,
  getReportIncomeDefaultWithdraw: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/relatorio-receita-inadimplencia-saque-avista?ano=PARAM_ANO&mes=PARAM_MES`,
  postReportFuturesInvoices: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-faturas-futuras`,
  postReportClosingInvoicesCycles: `${process.env.REACT_APP_MS_RELATORIO}/v1/relatorios/obter-relatorio-faturas-fechamento-ciclo/PARAM_YEAR`,
  postReportEmployee: `${process.env.REACT_APP_MS_FUNCIONARIO}/v1/usuarios/relatorio-funcionarios`,
};

const Sales = {
  getBenefits: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/obter-beneficios?cpf=PARAM_CPF`,
  getContractedBenefits: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/buscar-beneficio-proposta/PARAM_ID_PROPOSTA`,
  getBenefitContract: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/buscar-contrato-seguros/PARAM_ID_PROPOSTA/PARAM_ID_BENEFIT`,
  subscribeBenefits: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/salvar-beneficio-proposta?backOffice=PARAM_BACKOFFICE`,
  postDependent: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/adicionar-dependente/PARAM_ID_PROPOSTA?backOffice=PARAM_BACKOFFICE`,
  deleteBenefit: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/PARAM_ID_PROPOSTA/PARAM_ID_BENEFICIO`,
  getBenefitDetails: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/obter-dependentes-odonto/PARAM_ID_PROPOSTA`,
  deleteBenefitDependent: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/excluir-beneficio-dependente`,
  cancelBenefit: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/cancelamento-seguros-tokio`,
  cancelBenefitCuida: `${process.env.REACT_APP_MS_BENEFICIOS}/v1/beneficio/cancelamento-beneficio-cuida`,
};

const Fidelizacao = {
  getParameters: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/parametros/obter-parametros`,
  getSettings: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/configuracoes/obter-configuracoes`,
  postCreateCampaign: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/gerar-campanha`,
  postObterCampanha: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/obter-campanhas`,
  postObterClientes: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/clientes/obter-clientes-campanha?campanhaId=PARAM_ID_CAMPANHA`,
  putExecuteCampaign: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/executar-acao-campanha?campanhaId=PARAM_ID_CAMPANHA`,
  putCancelCampaign: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/cancelar-campanha?campanhaId=PARAM_ID_CAMPANHA`,
  putAtualizarConfig: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/configuracoes/atualizar-configuracoes`,
  putAtualizarParametros: `${process.env.REACT_APP_MS_AUMENTO_LIMITE}/v1/campanhas/parametros/atualizar-parametros`,
};

const Invoices = {
  getAntecipationCalculation: `${process.env.REACT_APP_MS_ANTECIPACAO_FATURA}/v1/antecipacoes/calcular-financiamento?idFinanciamento=PARAM_ID_FINANCIAMENTO&qtdParcelas=PARAM_QTD_PARCELAS`,
  getSearchFinancing: `${process.env.REACT_APP_MS_ANTECIPACAO_FATURA}/v1/antecipacoes/buscar-financiamentos?cpf=PARAM_CPF&numeroCartao=PARAM_NUMERO_CARTAO`,
  postAntecipation: `${process.env.REACT_APP_MS_ANTECIPACAO_FATURA}/v1/antecipacoes`,
  putAntecipationCancel: `${process.env.REACT_APP_MS_ANTECIPACAO_FATURA}/v1/antecipacoes/cancelar/PARAM_ID_PARCELAMENTO`,
  getFinancial: `${process.env.REACT_APP_MS_ANTECIPACAO_FATURA}/v1/antecipacoes/PARAM_ID_PARCELAMENTO`,
  getSearchPurchaseFinancing: `${process.env.REACT_APP_MS_ANTECIPACAO_FATURA}/v1/antecipacoes/buscar-compras?cpf=PARAM_CPF&numeroCartao=PARAM_NUMERO_CARTAO`,
  getAntecipationPurchaseCalculation: `${process.env.REACT_APP_MS_ANTECIPACAO_FATURA}/v1/antecipacoes/calcular-compra?idCompra=PARAM_ID_COMPRA&qtdParcelas=PARAM_QTD_PARCELAS`,
  getAntecipationPurchaseHistory: `${process.env.REACT_APP_MS_ANTECIPACAO_FATURA}/v1/antecipacoes/historico?cpf=PARAM_CPF`,
  // getFaturasAtraso:
  //   'http://localhost:8105/v1/s3bucket/download?bucketName=development-relatorios-propostas-p1&filename=faturasAtraso.csv',
  getFaturasAtraso: `${process.env.REACT_APP_MS_RELATORIO}/v1/cobrancas/noauth/export-csv`,
  getPendingTransactions: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/transacao-solicitada/listar-pendentes`,
  getTransactionDetail: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/transacao-solicitada/detalhes/PARAM_ID`,
  putTransactionDecision: `${process.env.REACT_APP_MS_AUTORIZADOR}/v1/autorizador/transacao-solicitada/decisao`,
  postReportUnpaidOpenInvoices: `${process.env.REACT_APP_MS_COBRANCA}/v1/cobrancas/gerar-relatorio-faturas-abertas-nao-pagas`,
};

const Graphc = {
  getGraphcPropostaStatus: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-propostas-status-D1?campoOrdenado=PARAM_CAMPO_ORDENADO&data=PARAM_DATA&tipoOrdenacao=PARAM_TIPO_ORDENACAO`,
  getUnblockedCard: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/quantidade-desbloqueados-hoje`,
  getAdhesionsOdontic: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/obter-adesoes-d0`,
  getDailyBilling: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/faturamento-diario-D1`,
  postBoxMonthly: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/obter-dados-dashboard-mensal-D1`,
  getConsultProposal: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-propostas/listar-propostas-status-D0`,
  // Client Base
  getClientStatus: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/grafico/obter-clientes-status?campoOrdenado=PARAM_CAMPO_ORDENADO&tipoOrdenacao=PARAM_TIPO_ORDENACAO`,
  getBlockedReasonStore: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/grafico/obter-clientes-loja-motivo-bloqueio`,
  getDownloadClientBase: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/export-csv/obter-relatorio-clientes`,

  // Listar faturas pagas
  getInvoicePaidDetails: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/fechamentos/listar-faturas-pagas?data=PARAM_DATA`,

  // Créditos Disponíveis
  getAvailableCredits: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/fechamentos/fatura-creditos-disponiveis?data=PARAM_DATA`,

  // Listar faturas em atraso
  getLateInvoiceDetails: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/fechamentos/listar-faturas-atraso?data=PARAM_DATA_ATRASO`,

  // Listar positivados
  getPositiveCustomers: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-base-clientes/export-csv/relatorio-positivados?periodoUltimaCompra=PARAM_QTD_DIAS&positivados=PARAM_POSITIVADOS`,

  // Limite ativos
  getActiveLimits: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-base-clientes/export-csv/relatorio-limites-ativos?limitesAtivos=PARAM_LIM_ATIVO`,

  // DetaisProposal
  getProposalDetails: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-detalhes-propostas-status-D1?dataFinal=PARAM_DATA_FINAL&dataInicial=PARAM_DATA_INICIAL&status=PARAM_STATUS`,
  // DetaisAdhesions
  getAdhesionsDetails: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/grafico/adesoes-loja-operador?dataInicial=PARAM_DATA_INICIAL&dataFinal=PARAM_DATA_FINAL`,
  // DetaiilsOdontoRealTime
  getOdontoDetailsRealTime: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-detalhes-beneficios-D0?dataFinal=PARAM_DATA_FINAL&dataInicial=PARAM_DATA_INICIAL`,
  // DetaisProposalRealTime
  getProposalDetailsRealTime: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-propostas/listar-detalhes-propostas-status-D0?dataFinal=PARAM_DATA_FINAL&dataInicial=PARAM_DATA_INICIAL&status=PARAM_STATUS`,
  getInvoiceClosing: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/fechamentos/obter-quantidade-fechamento-faturas?dataFechamento=PARAM_DATA_FECHAMENTO`,
  getClosingAmounts: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/obter-quantidades-fechamento?dataFechamento=PARAM_DATA_FECHAMENTO`,
  getEvolutionMonth: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/evolucao-mensal-dashboard`,
  getProposalHistoric: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-propostas-historico?dataAprovacao=PARAM_DATA_APROVACAO`,
  getProposalProjection: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-propostas-projecao?dataAprovacao=PARAM_DATA_APROVACAO`,
  getPurchaseBillingHistoric: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-faturamento-compra-historico?dataCompra=PARAM_DATA_COMPRA`,
  getPurchaseBillingProjection: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-faturamento-compra-projecao?dataCompra=PARAM_DATA_COMPRA`,
  getInvoicePaymentHistoric: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-faturamento-pagamento-historico?dataPagamento=PARAM_DATA_PAGAMENTO`,
  getInvoicePaymentProjection: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-faturamento-pagamento-projecao?dataPagamento=PARAM_DATA_PAGAMENTO`,
  getSalesOdontoHistoric: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-adesoes-odonto-historico?dataVenda=PARAM_DATA_VENDA`,
  getSalesOdontoProjection: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-adesoes-odonto-projecao?dataVenda=PARAM_DATA_VENDA`,
  getClientsActiveHistoric: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-clientes-historico?dataAprovacao=PARAM_DATA_APROVACAO`,
  getClientsActiveProjection: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-clientes-projecao?dataAprovacao=PARAM_DATA_APROVACAO`,
  getBenefitsDetails: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-detalhes-beneficio`,
  // Grafico Platinum
  postDetaAdesoesPlatinumD1: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-detalhes-platinum`,
};

const Loan = {
  getLoan: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-emprestimos-cartao-credito-paginado`,
  getWithDraw: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-emprestimos-saque-paginado`,
  getContractWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/contrato-por-cpf?cpf=PARAM_CPF`,
  getContractWithdrawBase64: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/contrato-emprestimo-pdf/PARAM_ID`,
  getDetailsLoanTransaction: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-detalhes-emprestimo/PARAM_ID_TRANSACAO`,
  postHistoryLoanCelcoin: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-historico-emprestimo-celcoin`,
  putCancelWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/cancelar/PARAM_ID_LOAN`,
  getInformationKYCClient: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-informacoes-cliente-kyc/PARAM_CPF`,
  deleteRemoveRestritionKYC: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/remover-restricao-kyc/PARAM_CPF`,
  getDetailsTransactionLoan: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-detalhes-emprestimo/PARAM_ID`,
};

const Chat = {
  getChat: `${process.env.REACT_APP_MS_CHAT}/chat/aberto`,
};

const Product = {
  getAllProducts: `${process.env.REACT_APP_MS_PRODUTO}/v1/produtos/todos-produtos-do-sistema`,
  activateProduct: `${process.env.REACT_APP_MS_PRODUTO}/v1/produtos/ativar-produto/ID_PRODUTO`,
  deactivateProduct: `${process.env.REACT_APP_MS_PRODUTO}/v1/produtos/desativar-produto/ID_PRODUTO`,
  changeImaUrl: `${process.env.REACT_APP_MS_PRODUTO}/v1/produtos/alterar-imagem-produto/ID_PRODUTO`,
};

const PostApp = {
  getAllPosts: `${process.env.REACT_APP_MS_POSTS}/v1/posts`,
  createPost: `${process.env.REACT_APP_MS_POSTS}/v1/posts`,
  activatePost: `${process.env.REACT_APP_MS_POSTS}/v1/posts/ativar/ID_POST`,
  deactivatePost: `${process.env.REACT_APP_MS_POSTS}/v1/posts/desativar/ID_POST`,
  deletePost: `${process.env.REACT_APP_MS_POSTS}/v1/posts/deletar/ID_POST`,
};

const S3Bucket = {
  uploadFile: `${process.env.REACT_APP_MS_S3_BUCKET}/v1/s3bucket/upload-file`,
  removeFile: `${process.env.REACT_APP_MS_S3_BUCKET}/v1/s3bucket/delete-file/KEY`,
};

const Events = {
  getEvent: `${process.env.REACT_APP_MS_POSTS}/v1/box-base-clientes/cliente-evento`,
  getClientContractedBenefits: `${process.env.REACT_APP_MS_POSTS}/v1/beneficio/evento/beneficios-contratados?cpf=PARAM_CPF`,
  getClientNotContractedBenefits: `${process.env.REACT_APP_MS_POSTS}/v1/beneficio/evento/beneficios-nao-contratados?cpf=PARAM_CPF`,
  getAvailableProductTypes: `${process.env.REACT_APP_MS_POSTS}/v1/produtos/listar-produtos-ativos-evento`,
  getOdontoDetails: `${process.env.REACT_APP_MS_POSTS}/v1/beneficio/evento/beneficio-odonto-contratado-detalhes?cpf=PARAM_CPF&nome=PARAM_NOME`,
  postEvent: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/solicitar-venda`,
  getEventList: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/obter-caixa-evento-paginado`,
  postNextContact: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/solicitar-proximo-contato`,
  postForwardLead: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/solicitar-encaminhamento`,
  getRetProfiles: `${process.env.REACT_APP_MS_POSTS}/v1/usuarios/obter-perfis-retaguarda`,
  getSupProfiles: `${process.env.REACT_APP_MS_POSTS}/v1/usuarios/obter-perfis-supervisor`,
  getContactHistory: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/evento-contato-historico?idEvento=PARAM_ID`,
  getClientPhoto: `${process.env.REACT_APP_MS_POSTS}/v1/box-base-clientes/cliente-foto?cpf=PARAM_CPF`,
  postCloseLead: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/encerrar-evento`,
  getLeadDetail: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/evento-log?idEvento=PARAM_ID`,
  postLeadDecision: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/decisao-evento`,
  getLeadBenefits: `${process.env.REACT_APP_MS_EVENTO_BENEFICIO}/v1/eventos-beneficios?idEvento=PARAM_ID`,
  getLeadHistoric: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/evento-acao-historico`,
  getLeadUniqueness: `${process.env.REACT_APP_MS_EVENTOS}/v1/eventos/valida-unicidade-evento`,
  postIncludeEvent: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/incluir-evento`,
  postFinishEvent: `${process.env.REACT_APP_MS_POSTS}/v2/eventos/executar-evento/PARAM_ID_EVENTO`,
  // Relatório de vendas Platinum
  postReportSalesPlatinum: `${process.env.REACT_APP_MS_POSTS}/v1/eventos/relatorio-vendas-platinum`,
  // Grafico Platinum
  postDetaAdesoesPlatinumD0: `${process.env.REACT_APP_MS_EVENTO_BENEFICIO}/v1/eventos/listar-detalhes-adesoes-platinum`,
  postReportEventCancelation: `${process.env.REACT_APP_MS_EVENTO_BENEFICIO}/v1/eventos/relatorio-validacao-cancelamento`,
  postReportCancellationPlatinum: `${process.env.REACT_APP_MS_EVENTOS}/v1/eventos/relatorio-cancelamentos-platinum`,
  postDesistirCancelamento: `${process.env.REACT_APP_MS_EVENTOS}/v1/eventos/desistir-cancelamento/PARAM_ID`,
};

const UseCases = {
  getCardInfoEvent: `${process.env.REACT_APP_MS_USE_CASES}/v1/use-cases/cartao?idEvento=PARAM_ID`,
  getAccountInfoEventCancel: `${process.env.REACT_APP_MS_USE_CASES}/v1/use-cases/conta/obter-mensagem-desistencia?idEvento=PARAM_ID`,
  getCancelLeadDetails: `${process.env.REACT_APP_MS_USE_CASES}/v1/use-cases/conta/PARAM_ID`,
};

const Endpoints = {
  account: Account,
  consult: Consult,
  register: Register,
  update: Update,
  card: Card,
  create: Create,
  files: Files,
  report: Report,
  sales: Sales,
  product: Product,
  postApp: PostApp,
  fidelizacao: Fidelizacao,
  invoices: Invoices,
  s3Bucket: S3Bucket,
  graphc: Graphc,
  chat: Chat,
  loan: Loan,
  events: Events,
  useCases: UseCases,
};

export default Endpoints;
