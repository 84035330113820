export const REPORT_TYPES = [
  // { id: '6', value: 'Transações' },
  { id: '11', value: 'Vendas' },
  { id: '19', value: 'Clientes Positivados' },
  { id: '20', value: 'Limites Alterados' },
  { id: '22', value: 'Clientes Reativados' },
  { id: '23', value: 'Contratos Assinados' },
  { id: '26', value: 'Cadastro de Anfitrião' },
  { id: '27', value: 'Funcionário' },
  // { id: '24', value: 'Relatório COBMAIS' },
];

export const CSV_HEADERS = {
  INVOICE_DELAY: [
    { label: 'Número do Cartão', key: 'numeroCartao' },
    { label: 'Valor', key: 'saldoFaturaAtual' },
    { label: 'Data de Vencimento', key: 'dtVencimento' },
    { label: 'Dias em Atraso', key: 'diasAtraso' },
    { label: 'Nome', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Email', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Celular 2', key: 'celular2' },
    { label: 'Score SPC', key: 'scoreSpc' },
    { label: 'Renda Presumida', key: 'rendaPresumida' },
    { label: 'Data de Nascimento', key: 'dataNascimento' },
    { label: 'Nome Referência 1', key: 'nomeReferencia1' },
    { label: 'Telefone 1', key: 'telefone1' },
    { label: 'Nome Referência 2', key: 'nomeReferencia2' },
    { label: 'Telefone 2', key: 'telefone2' },
    { label: 'Limite Concedido Saque', key: 'limiteSaqueConcedido' },
    { label: 'Limite Utilizado Saque', key: 'limiteSaqueUtilizado' },
  ],
  REPORT_DISAPPROVED: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular', key: 'celular' },
    { label: 'Origem', key: 'origem' },
    { label: 'Loja', key: 'loja' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Data e Horario Reprovação', key: 'dataReprovacao' },
    { label: 'Motivo', key: 'motivoReprovacao' },
    { label: 'Data da proposta P1', key: 'dataProposta' },
    { label: 'Score', key: 'score' },
    { label: 'Email ', key: 'email' },
    { label: 'Celular 2', key: 'celular2' },
    { label: 'UF', key: 'uf' },
    { label: 'UF-Loja', key: 'ufLoja' },
    { label: 'Usuário', key: 'nomeUsuarioMesa' },
    { label: 'Código do anfitrião', key: 'codigoAnfitriao' },
    { label: 'Nome do anfitrião', key: 'nomeAnfitriao' },
    { label: 'Readmitido', key: 'readmissao' },
  ],
  REPORT_LEADS_SALES: [
    { label: 'Nome do Cliente', key: 'cliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Email ', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Operador(a) de Venda', key: 'operador[nome]' },
    {
      label: 'Valor da Transação',
      key: 'valorTransacao',
    },
    { label: 'Valor da Parcela', key: 'valorParcela' },
    { label: 'Quantidade de Parcelas', key: 'numeroParcelas' },
    { label: 'Valor do Emprestimo', key: 'valorLiberado' },
    { label: 'Taxa de Juros Aplicada', key: 'taxaJuros' },
    { label: 'Data da Contratação', key: 'dataContratacao' },
    { label: 'Data do Pagamento', key: 'dataPagamento' },
    { label: 'Status', key: 'statusNovoSaque_i18n' },
    { label: 'UF', key: 'endereco[uf]' },
  ],
  REPORT_WITHDRAW_LOAN: [
    { label: 'CPF', key: 'cliente[cpf]' },
    { label: 'Email', key: 'cliente[email]' },
    { label: 'Celular', key: 'cliente[celular]' },
    { label: 'UF', key: 'cliente[endereco][uf]' },
    { label: 'Operador', key: 'operador[nome]' },
    { label: 'Valor do saque', key: 'valorLiberado' },
    { label: 'Valor da parcela', key: 'valorParcela' },
    { label: 'Tac', key: 'tacAmount' },
    { label: 'Taxa de juros aplicada', key: 'taxaJuros' },
    { label: 'Data de contratacao', key: 'dataEnvio' },
    { label: 'Data de desembolso', key: 'dataPagamento' },
    { label: 'data de vencimento', key: 'dataVencimento' },
    { label: 'Status', key: 'statusCelcoin_i18n' },
    { label: 'Descricao', key: 'descricao' },
    { label: 'ID', key: 'id' },
    { label: 'Sequencial Solicitacao', key: 'idSequencial' },
  ],
  REPORT_WITHDRAW_BILLING: [
    { label: 'Nome do cliente', key: 'cliente[nome]' },
    { label: 'CPF', key: 'cliente[cpf]' },
    { label: 'Email', key: 'cliente[email]' },
    { label: 'Celular', key: 'cliente[celular]' },
    { label: 'Saque', key: 'valorLiberado' },
    { label: 'Juros', key: 'valorJuros' },
    { label: 'TAC', key: 'tacAmount' },
    { label: 'IOF', key: 'valorIOF' },
    { label: 'Data desembolso', key: 'dataPagamento' },
    { label: 'Data de vencimento', key: 'dataPrimeiroPagamento' },
    { label: 'Data de pagamento', key: 'dataPagamentoFatura' },
  ],
  CANCELLATION_VALIDATION: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular', key: 'celular' },
    { label: 'E-mail', key: 'email' },
    { label: 'Data do evento', key: 'dataInclusaoEvento' },
    { label: 'Data da Verificação', key: 'dataValidacao' },
    { label: 'Operador(a) do evento', key: 'operador[nome]' },
    { label: 'Tipo de cancelamento', key: 'tipoCancelamento' },
    { label: 'Motivo', key: 'motivo' },
  ],
  REPORT_LOAN_SEEKER: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Email', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Operador', key: 'operador' },
    { label: 'Propostas', key: 'produto' },
    { label: 'Data de criação', key: 'dataCriacao' },
    { label: 'Valor', key: 'valor' },
    { label: 'Juros', key: 'taxaMensal' },
    { label: 'Parcelas', key: 'qtdParcela' },
    { label: 'Status', key: 'status' },
    { label: 'Link Fornecedor', key: 'linkProduto' },
  ],
  REPORT_APPROVED: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular', key: 'celular' },
    { label: 'Loja', key: 'loja' },
    { label: 'Origem', key: 'origem' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    {
      label: 'Data e Horário Aprovação',
      key: 'dataAprovacao',
    },
    { label: 'Valor limite', key: 'valorLimite' },
    { label: 'Motivo', key: 'motivoReprovacao' },
    { label: 'Data da proposta P1', key: 'dataProposta' },
    { label: 'Score', key: 'score' },
    { label: 'Email ', key: 'email' },
    { label: 'Celular 2', key: 'celular2' },
    { label: 'UF', key: 'uf' },
    { label: 'Usuário', key: 'nomeUsuarioMesa' },
    { label: 'UF-Loja', key: 'ufLoja' },
    { label: 'Categoria', key: 'categoriaCartao' },
    { label: 'Código do anfitrião', key: 'codigoAnfitriao' },
    { label: 'Nome do anfitrião', key: 'nomeAnfitriao' },
    { label: 'Readmitido', key: 'readmissao' },
  ],
  INVOICE_ISSUED: [
    { label: 'Nome', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular', key: 'celular' },
    { label: 'Email', key: 'email' },
    { label: 'Data de Vencimento', key: 'dataVencimento' },
    { label: 'Valor da Fatura', key: 'saldoFaturaAtual' },
    { label: 'Data de Pagamento', key: 'dataPagamento' },
    { label: 'Valor Pago', key: 'valorPagamento' },
    { label: 'Status', key: 'status' },
    { label: 'Valor Saque', key: 'valorSaque' },
  ],
  IOF: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data de pagamento', key: 'pagamento' },
    { label: 'Valor Base', key: 'valorBase' },
    { label: 'Prazo Apurado', key: 'prazo' },
    { label: 'Valor do IOF cobrado', key: 'iofCobrado' },
  ],
  TRANSACTIONS: [
    { label: 'Data da Transação', key: 'data' },
    { label: 'Estabelecimento', key: 'estabelecimento' },
    { label: 'Número do Cartão', key: 'numeroCartao' },
    { label: 'Parcela', key: 'parcela_atual' },
    { label: 'Valor', key: 'valor' },
    { label: 'Valor da Compra', key: 'valorCompra' },
  ],
  TRADITIONAL: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Operador(a)', key: 'operador' },
    { label: 'Origem', key: 'origem' },
  ],
  SALES: [
    { label: 'Nome', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Estabelecimento', key: 'estabelecimento' },
    { label: 'Código de estabelecimento', key: 'codigoNovaEraEstabelecimento' },
    { label: 'Data da Venda', key: 'data' },
    { label: 'Parcelas', key: 'parcelas' },
    { label: 'Valor', key: 'valorCompra' },
    { label: 'UF Loja', key: 'ufEstabelecimento' },
  ],
  INACTIVE_CARDS: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Origem', key: 'origem' },
    { label: 'Celular', key: 'celular' },
    { label: 'E-mail', key: 'email' },
    { label: 'Valor limite', key: 'valorLimite' },
    { label: 'Cartão Impresso', key: 'cartaoImpresso' },
    { label: 'Contrato Assinado', key: 'contratoAssinado' },
    { label: 'Data e Horário Aprovação', key: 'dataAprovacao' },
    { label: 'Data e Horário Impressão', key: 'dataImpressao' },
    { label: 'UF', key: 'uf' },
    { label: 'UF-Loja', key: 'ufLoja' },
    { label: 'Categoria do cartão', key: 'categoriaCartao' },
  ],
  UNLOCKED_CARDS: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular', key: 'celular' },
    { label: 'E-mail', key: 'email' },
    { label: 'Data Aprovação', key: 'dataAprovacao' },
    { label: 'Data desbloqueio', key: 'cartaoAtual.dataAtivacao' },
    { label: 'Loja', key: 'loja' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Origem', key: 'origem' },
    { label: 'UF', key: 'uf' },
    { label: 'UF-Loja', key: 'ufLoja' },
    { label: 'Categoria do cartão', key: 'categoriaCartao' },
    { label: 'Código do anfitrião', key: 'codigoAnfitriao' },
    { label: 'Nome do anfitrião', key: 'nomeAnfitriao' },
  ],
  SALES_PLATINUM_CARD: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular', key: 'celular' },
    { label: 'E-mail', key: 'email' },
    { label: 'Data Adesâo', key: 'dataAdesao' },
    { label: 'Operador(a) de Venda', key: 'operadorVenda' },
    { label: 'Tipo Adesão ', key: 'tipoAdesao' },
    { label: 'Loja', key: 'loja' },
    { label: 'UF-Loja', key: 'lojaUF' },
  ],
  PROPOSALS_REGISTERED: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular', key: 'celular' },
    { label: 'Email', key: 'email' },
    { label: 'Loja', key: 'loja' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Origem', key: 'origem' },
    { label: 'Data Proposta', key: 'dataProposta' },
    { label: 'Data Aprovação', key: 'dataAprovacao' },
    { label: 'Score', key: 'score' },
    { label: 'UF', key: 'uf' },
    { label: 'UF-Loja', key: 'ufLoja' },
    { label: 'Código do anfitrião', key: 'codigoAnfitriao' },
    { label: 'Nome do anfitrião', key: 'nomeAnfitriao' },
    { label: 'Readmitido', key: 'readmissao' },
  ],
  ODONTO: [
    { label: 'Nome cliente', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data da Venda', key: 'dataVenda' },
    { label: 'Operador(a) de Venda', key: 'nomeOperador' },
    { label: 'Status cartão', key: 'statusCartao' },
    { label: 'Status Beneficio', key: 'statusBeneficio' },
    { label: 'E-mail', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Celular 2', key: 'celular2' },
    { label: 'Titular/Dependente', key: 'tipoUsuario' },
    { label: 'Nome dependente', key: 'nomeDependente' },
    { label: 'Data Nascimento dependente', key: 'dataNascimento' },
    { label: 'UF', key: 'uf' },
    { label: 'Loja', key: 'loja' },
    { label: 'UF - Loja', key: 'ufLoja' },
    { label: 'Isenção', key: 'isencao' },
  ],
  CUIDA: [
    { label: 'Nome cliente', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'E-mail', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Celular 2', key: 'celular2' },
    { label: 'Data da venda', key: 'dataVenda' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Loja da Venda', key: 'loja' },
    { label: 'Status cartão TeuCard', key: 'statusCartao' },
    { label: 'Status Beneficio', key: 'statusBeneficio' },
    { label: 'UF', key: 'uf' },
    { label: 'UF - Loja', key: 'ufLoja' },
  ],
  EMPLOYEE: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Status', key: 'ativo' },
    { label: 'Bloqueado', key: 'bloqueado' },
    { label: 'Perfil1', key: 'perfil1' },
    { label: 'Perfil2', key: 'perfil2' },
  ],
  CANCEL_CUIDA: [
    { label: 'Nome cliente', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data da Venda', key: 'dataVenda' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Loja da Venda', key: 'loja' },
    { label: 'Data do cancelamento', key: 'dataCancelamento' },
    { label: 'Motivo do cancelamento', key: 'motivoCancelamento' },
    { label: 'Status cartão', key: 'statusCartao' },
    { label: 'UF', key: 'uf' },
  ],
  CANCEL_ODONTO: [
    { label: 'Nome cliente', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data da Venda', key: 'dataVenda' },
    { label: 'Operador(a) de Venda', key: 'nomeOperador' },
    { label: 'Data do cancelamento', key: 'dataCancelamento' },
    { label: 'Status cartão', key: 'statusCartao' },
    { label: 'Nome dependente', key: 'nomeDependente' },
    { label: 'Origem do cancelamento', key: 'origemCancelamento' },
    { label: 'Motivo do cancelamento', key: 'motivoCancelamento' },
    { label: 'Tipo do cancelamento', key: 'tipoCancelamento' },
    { label: 'UF', key: 'uf' },
    { label: 'Data conciliação do arquivo', key: 'dataConciliacao' },
    { label: 'ID do autorizador', key: 'idAutorizador' },
  ],
  BILLING_ODONTO: [
    { label: 'Nome cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'E-mail', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Vencimento', key: 'vencimento' },
    { label: 'Status Fatura', key: 'status' },
    { label: 'Quantidade Adesões', key: 'quantidadeAdesao' },
    { label: 'Data de Adesão', key: 'dataAdesao' },
    { label: 'Data de Pagamento', key: 'dataPagamento' },
    { label: 'Valor Pago', key: 'valor' },
    { label: 'Número do Cartão', key: 'numeroCartao' },
    { label: 'Loja', key: 'loja' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Origem', key: 'origem' },
    { label: 'Quantidade Adesões', key: 'qtdPlanos' },
  ],
  CANCELLATION_PLATINUM: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data da venda', key: 'dataAdesao' },
    { label: 'Operador(a) de venda', key: 'operadorVenda' },
    { label: 'Loja da venda', key: 'loja' },
    { label: 'UF', key: 'lojaUF' },
    { label: 'Data do cancelamento', key: 'dataCancelamento' },
    {
      label: 'Motivo do Cancelamento (Campo Parecer)',
      key: 'motivoCancelamento',
    },
  ],
  CHARGES: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Email', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Data de Vencimento', key: 'vencimento' },
    { label: 'Número de Cartão', key: 'cartao' },
    { label: 'Valor', key: 'valor' },
    { label: 'Data de Nascimento', key: 'nascimento' },
    { label: 'Logradouro', key: 'logradouro' },
    { label: 'Número', key: 'numero' },
    { label: 'Complemento', key: 'complemento' },
    { label: 'Bairro', key: 'bairro' },
    { label: 'CEP', key: 'cep' },
    { label: 'Cidade', key: 'cidade' },
    { label: 'UF', key: 'uf' },
  ],
  FINANCIAL_INCOME: [
    { label: 'CPF', key: 'cpf' },
    { label: 'Cartão', key: 'cartao' },
    { label: 'Nome Completo', key: 'nome' },
    { label: 'Tipo de encargo', key: 'tipo' },
    { label: 'Vencimento', key: 'vencimento' },
    { label: 'Pagamento', key: 'pagamento' },
    { label: 'Valor', key: 'valor' },
  ],
  INVOICES_RECEIVED: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Cartão', key: 'cartao' },
    { label: 'Data pagamento', key: 'dataPagamento' },
    { label: 'Valor', key: 'valorPagamento' },
    { label: 'Origem', key: 'origem' },
    { label: 'Unidade', key: 'unidade' },
  ],
  APPROVED_P1: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Celular', key: 'celular' },
    { label: 'Origem', key: 'origem' },
    { label: 'Data e Horário Proposta', key: 'dataProposta' },
    { label: 'Data e Horário Aprovação', key: 'dataAprovacao' },
    { label: 'Score', key: 'score' },
    { label: 'Email', key: 'email' },
    { label: 'UF', key: 'uf' },
    { label: 'UF-Loja', key: 'ufLoja' },
    { label: 'Código do anfitrião', key: 'codigoAnfitriao' },
    { label: 'Nome do anfitrião', key: 'nomeAnfitriao' },
    { label: 'Readmitido', key: 'readmissao' },
  ],
  DISAPPROVED_P1: [
    { label: 'Nome completo', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Celular', key: 'celular' },
    { label: 'Origem', key: 'origem' },
    { label: 'Data e Horário Reprovação', key: 'dataReprovacao' },
    { label: 'Score', key: 'score' },
    { label: 'Motivo', key: 'motivoReprovacao' },
    { label: 'Email', key: 'email' },
    { label: 'UF-Loja', key: 'ufLoja' },
    { label: 'Código do anfitrião', key: 'codigoAnfitriao' },
    { label: 'Nome do anfitrião', key: 'nomeAnfitriao' },
    { label: 'Readmitido', key: 'readmissao' },
  ],
  RECIPE_AGREEMENT: [
    { label: 'CPF', key: 'cpf' },
    { label: 'Nome completo', key: 'nome' },
    { label: 'Resultado', key: 'resultado' },
    { label: 'Valor Dívida', key: 'valorDivida' },
    { label: 'Valor Pago', key: 'valorPago' },
    { label: 'Valor Principal', key: 'valorPrincipal' },
    { label: 'Data Inclusão', key: 'dataInclusao' },
    { label: 'Data Decisao', key: 'dataDecisao' },
    { label: 'Dias de atraso', key: 'diasAtraso' },
  ],
  CASHBACK_ZEUSS: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular Zeuss', key: 'celularZeuss' },
    { label: 'Valor Compra', key: 'valorCompras' },
    { label: 'Valor CashBack', key: 'valorCashBack' },
    { label: 'Data Vencimento Fatura', key: 'dataVencimentoFatura' },
    { label: 'Data Pagamento Fatura', key: 'dataPagamento' },
  ],
  POSITIVE_RESGISTRATION: [
    { label: 'Nome', key: 'nome' },
    { label: 'Número Cartão', key: 'numeroCartao' },
    { label: 'Data Última Compra', key: 'dataUltimaCompra' },
    { label: 'Valor Última Compra', key: 'valorUltimaCompra' },
    { label: 'Limite Disponível', key: 'limiteDisponivel' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Email', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Celular 2', key: 'celular2' },
  ],
  CHANGED_LIMIT: [
    { label: 'Nome Completo', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data Alteração', key: 'dataAumentoLimite' },
    { label: 'Origem', key: 'tipoAumento' },
    { label: 'Valor Sugerido', key: 'valorSugeridoMotor' },
    { label: 'Valor estabelecido', key: 'valorEstabelecido' },
    { label: 'Número do Cartão', key: 'numeroCartao' },
    { label: 'Email', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Celular 2', key: 'celular2' },
    { label: 'Score SPC', key: 'score' },
    { label: 'Data Nascimento', key: 'dataNascimento' },
    { label: 'Renda Presumida', key: 'rendaPresumida' },
  ],

  FUTURE_INVOICE: [
    { label: 'Nome', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Cartão', key: 'cartao' },
    { label: 'Data Vencimento', key: 'vencimento' },
    { label: 'Valor', key: 'valor' },
    { label: 'Parcela Atual', key: 'parcelaAtual' },
    { label: 'Total de Parcelas', key: 'totalParcelas' },
    { label: 'Data da Compra', key: 'dataCompra' },
  ],

  MANUALLY_INVOICE: [
    { label: 'Nome', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Celular', key: 'celular' },
    { label: 'Email', key: 'email' },
    { label: 'Data Aprovação', key: 'dataAprovacao' },
    { label: 'Valor', key: 'valor' },
    { label: 'Parcela', key: 'parcela' },
    { label: 'Solicitante', key: 'solicitante' },
    { label: 'Aprovador', key: 'aprovador' },
    { label: 'Parecer', key: 'parecer' },
    { label: 'Tipo de Transação', key: 'tipoTransacao' },
  ],

  REACTIVATED_CUSTOMERS: [
    { label: 'Nome', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Email', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Cartão Devolvido', key: 'cartaoDevolvido' },
    { label: 'Número do Cartão', key: 'numeroCartao' },
    { label: 'Data Reativação', key: 'dataReativacao' },
    { label: 'Data Nascimento', key: 'dtNascimento' },
    { label: 'Valor Limite', key: 'valorLimite' },
    { label: 'Renda Presumida', key: 'rendaPresumida' },
    { label: 'Score SPC', key: 'scoreSpc' },
    { label: 'Operador(a) Inclusão', key: 'nomeOperadorInclusao' },
    { label: 'Operador(a) Decisão', key: 'nomeOperadorDecisao' },
  ],
  SIGNED_CONTRACTS: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'UF', key: 'uf' },
    { label: 'Email', key: 'email' },
    { label: 'Celular 1', key: 'celular1' },
    { label: 'Celular 2', key: 'celular2' },
    { label: 'Data de aprovação', key: 'dataAprovacao' },
    { label: 'Contrato Assinado', key: 'contratoAssinado' },
    { label: 'Data da assinatura', key: 'dataAssinatura' },
    { label: 'Cartão impresso', key: 'cartaoImpresso' },
    { label: 'Data de impressão', key: 'dataImpressao' },
    { label: 'Status cartão', key: 'statusCartao' },
  ],
  TEUCARD_PROTEGE: [
    { label: 'Nome do cliente', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data da Venda', key: 'dataVenda' },
    { label: 'Operador(a) de Venda', key: 'operador' },
    { label: 'Loja da Venda', key: 'loja' },
    { label: 'E-mail', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Celular 2', key: 'celular2' },
    { label: 'UF', key: 'uf' },
    { label: 'Status cartão', key: 'statusCartao' },
    { label: 'Status Beneficio', key: 'statusBeneficio' },
    { label: 'Tipo de Seguro', key: 'tipoSeguro' },
    { label: 'Número do Bilhete', key: 'numeroBilhete' },
    { label: 'Número da Sorte', key: 'numeroSorte' },
    { label: 'ID Segurado API', key: 'idSegurado' },
    { label: 'ID Solicitação API', key: 'idSolicitacao' },
    { label: 'Data envio API', key: 'dataEnvioApi' },
    { label: 'UF-Loja', key: 'ufLoja' },
  ],
  CANCEL_PROTEGE: [
    { label: 'Nome cliente', key: 'nomeCliente' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data da Venda', key: 'dataVenda' },
    { label: 'Operador(a) de Venda', key: 'nomeOperador' },
    { label: 'Loja da Venda', key: 'loja' },
    { label: 'Tipo de Seguro', key: 'tipoBeneficio' },
    { label: 'Data do cancelamento', key: 'dataCancelamento' },
    { label: 'Status cartão', key: 'statusCartao' },
    { label: 'UF', key: 'uf' },
    { label: 'Motivo do cancelamento', key: 'motivoCancelamento' },
    { label: 'Número do Bilhete', key: 'numeroBilhete' },
    { label: 'Número da Sorte', key: 'numeroSorte' },
    { label: 'ID Segurado API', key: 'idSegurado' },
    { label: 'ID Solicitação API', key: 'idSolicitacao' },
  ],
  CARTEIRA_DIGITAL_ADESAO: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'E-mail', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Operador(a) de Venda', key: 'nomeOperador' },
    { label: 'Data da Adesão', key: 'dataAdesao' },
    { label: 'Loja', key: 'loja' },
    { label: 'Possui cartão TeuCard', key: 'cartaoTeuCard' },
    { label: 'UF', key: 'uf' },
  ],
  CARTEIRA_DIGITAL_VENDA: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Operador(a)', key: 'nomeOperador' },
    { label: 'E-mail', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'UF', key: 'uf' },
    { label: 'Tipo Ação', key: 'tipoAcao' },
    { label: 'Data Lead', key: 'dataEnvio' },
    { label: 'Data Expiração', key: 'dataExpiracao' },
    { label: 'Data Leitura', key: 'dataLeitura' },
    { label: 'Data Adesão', key: 'dataAdesao' },
    { label: 'Data Primeira Transação', key: 'dataTransacao' },
    { label: 'Status', key: 'status' },
    { label: 'Valor Cupom', key: 'valorCupomTransacao' },
    { label: 'Qtd Parcelas', key: 'parcelasTransacao' },
    { label: 'Valor Parcela', key: 'valorParcela' },
    { label: 'Valor Transação', key: 'valorTransacao' },
    { label: 'Bandeira', key: 'bandeira' },
    { label: 'Cartão', key: 'cartaoQuatroUltimoDigitos' },
  ],
  CARTEIRA_DIGITAL_TRANSACAO: [
    { label: 'Nome do cliente', key: 'nome' },
    { label: 'Operação', key: 'operacao' },
    { label: 'Tipo', key: 'tipo' },
    { label: 'Bandeira', key: 'bandeira' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Data e hora', key: 'dataHora' },
    { label: 'Valor cupom', key: 'valorCupom' },
    { label: 'Valor parcela', key: 'valorParcela' },
    { label: 'Número Cartão Final', key: 'numeroCartao' },
    { label: 'Qtd parcela', key: 'parcela' },
    { label: 'Valor transação', key: 'valorTransacao' },
    { label: 'ID transação', key: 'idTransacao' },
    { label: 'ID autorizador', key: 'idAutorizador' },
    { label: 'NSU autorizador', key: 'nsuAutorizador' },
    { label: 'NSU SITEF', key: 'nsuSitef' },
    { label: 'NSU TEF', key: 'nsuTef' },
    { label: 'NSU REDE captura', key: 'nsuREDECaptura' },
    { label: 'Terminal', key: 'terminal' },
    { label: 'Estabelecimento', key: 'estabelecimento' },
    { label: 'Nome estabelecimento', key: 'nomeEstabelecimento' },
    { label: 'Status', key: 'status' },
    { label: 'Motivo', key: 'motivoRejeicao' },
    { label: 'NSU Host', key: 'nsuHost' },
  ],
  ANFITRIAO: [
    { label: 'Nome', key: 'nome' },
    { label: 'CPF', key: 'cpf' },
    { label: 'Email', key: 'email' },
    { label: 'Celular', key: 'celular' },
    { label: 'Código Anfitrião', key: 'codigo' },
    { label: 'Data Cadastro', key: 'dataCadastro' },
    { label: 'Status', key: 'status' },
    { label: 'Data Status', key: 'dataStatus' },
    { label: 'Data utilização', key: 'dataUltimaUtilizacao' },
    { label: 'Perfil do Anfitrião', key: 'perfil' },
    { label: 'Funcionário Nova Era?', key: 'novaEra' },
    { label: 'Link', key: 'link' },
  ],
};

export const UFs = [
  { value: 'Todos', id: 'TODOS' },
  { value: 'Acre', id: 'AC' },
  { value: 'Alagoas', id: 'AL' },
  { value: 'Amapá', id: 'AP' },
  { value: 'Amazonas', id: 'AM' },
  { value: 'Bahia', id: 'BA' },
  { value: 'Ceará', id: 'CE' },
  { value: 'Distrito Federal', id: 'DF' },
  { value: 'Espírito Santo', id: 'ES' },
  { value: 'Goiás', id: 'GO' },
  { value: 'Maranhão', id: 'MA' },
  { value: 'Mato Grosso', id: 'MT' },
  { value: 'Mato Grosso do Sul', id: 'MS' },
  { value: 'Minas Gerais', id: 'MG' },
  { value: 'Pará', id: 'PA' },
  { value: 'Paraíba', id: 'PB' },
  { value: 'Paraná', id: 'PR' },
  { value: 'Pernambuco', id: 'PE' },
  { value: 'Piauí', id: 'PI' },
  { value: 'Rio de Janeiro', id: 'RJ' },
  { value: 'Rio Grande do Norte', id: 'RN' },
  { value: 'Rio Grande do Sul', id: 'RS' },
  { value: 'Rondônia', id: 'RO' },
  { value: 'Roraima', id: 'RR' },
  { value: 'Santa Catarina', id: 'SC' },
  { value: 'São Paulo', id: 'SP' },
  { value: 'Sergipe', id: 'SE' },
  { value: 'Tocantins', id: 'TO' },
];

export const tipoBandeira = [
  { value: 'Todos', id: 'TODOS' },
  { value: 'Bandeirado', id: 'BANDEIRADO' },
  { value: 'TeuCard', id: 'TEUCARD' },
];
export const tipoStatus = [
  { value: 'Aprovado', id: 'APROVADO' },
  { value: 'Rejeitado', id: 'REJEITADO' },
  { value: 'Processando', id: 'PROCESSANDO' },
  { value: 'Desfazimento', id: 'DESFAZIMENTO' },
  { value: 'Cancelado', id: 'CANCELADO' },
  { value: 'Erro', id: 'ERRO' },
];
