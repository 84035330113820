/* eslint-disable default-case */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import useApi, { ApiMethod } from '~/hooks/useApi';
import endpoints from '~/services/endpoints';
import {
  ReportResponse,
  ReportResponseOdonto,
  ReportTransactionResponse,
  ReportIOFResponse,
  ReportDelayResponse,
  ReportInvoiceIssuedResponse,
  ReportSalesResponse,
  ReportProposalsRegisteredResponse,
  GetOperatorsResponse,
  GetCompaniesResponse,
  ReportChargeResponse,
  ReportFinancialIncomeResponse,
  ReportInvoicesReceivedResponse,
  ReportRecipeAgreementResponse,
  ReportBillingOdontoResponse,
  ReportPositiveRegistrationResponse,
  ReportChangedLimitResponse,
  ReportFutureInvoiceResponse,
  ReportReactivatedCustomersResponse,
  ReportSignedContractsResponse,
  ReportResponseCancelOdonto,
  ReportTeucardProtegeResponse,
  ReportLoanLeadsSalesResponse,
} from '~/services/report/requestReport/response';
import {
  GetReportTransactionPayload,
  GetReportIOFPayload,
  ReportSalesPayload,
  GetReportChargePayload,
  ReportRecipeAgreementPayload,
  ReportPositiveRegistrationPayload,
  ReportChangedLimitPayload,
  ReportReactivatedCustomersPayload,
  ReportSignedContractsPayload,
  ReportTeucardProtegePayload,
  ReportLoanLeadsSalesPayload,
} from '~/services/report/requestReport/payload';

import Container from '~/components/container';
import Select from '~/components/select';
import ContainerFlex from '~/components/containerFlex';
import Input, { MaskTypes } from '~/components/input';
import Button from '~/components/button';
import { TitleCategory } from '~/views/Consult/User/Detail/styles';
import Loading from '~/components/loading';

import * as St from './styles';
import { icons } from '~/assets';
import Dialog from '~/components/dialog';
import Pagination from '~/components/pagination';
import { CSV_HEADERS, REPORT_TYPES, UFs } from './constants';
import TableList from '~/components/tableList';

import Models from './models';
import AutoCompleteSelect from '~/components/AutoCompleteSelect';

// Teste pipline

const Report: React.FC = () => {
  const [items, setItems] = useState([]);
  const [headerItems, setHeaderItems] = useState([]);
  const [operadores, setOperadores] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [header, setHeader] = useState([]);
  const [downloadCVSProgress, setDownloadCVSProgress] = useState('');
  const [relatorioData, setRelatorioData] = useState([]);
  const [operadoresName, setOperadoresName] = useState([]);
  const [totalItems, setTotalItems] = useState<null | number>(null);
  const [form, setForm] = useState({
    idAtendente: 'TODOS',
    atendenteName: 'TODOS',
    origem: 'TODOS',
    cartaoImpresso: 'TODOS',
    cartaoDevolvido: 'TODOS',
    tipoPeriodo: 'DATA_APROVACAO',
    periodoFaturamentoOdonto: 'DT_ADESAO',
    tipoPeriodoCancelamentoOdonto: 'DT_CANCELAMENTO',
    limitePorPagina: 10,
    pagina: 1,
    order: 'ASC',
    periodoInicial: moment().format('DD/MM/YYYY'),
    periodoFinal: moment().format('DD/MM/YYYY'),
    dataFinal: moment().format('DD/MM/YYYY'),
    dataInicial: moment().format('DD/MM/YYYY'),
    primeiraCompra: 'string',
    relatorio: '11',
    statusCartao: 'TODOS',
    tipoDeEncargo: '',
    origemPagamento: 'TODAS',
    statusFatura: 'TODOS',
    tipoDeData: 'DATA_INCLUSAO',
    positiveUser: 'SIM',
    tipoAumento: 'TODOS',
    tipoFatura: 'TODOS',
    contratoAssinado: 'TODOS',
    periodoInicialAssinatura: '',
    periodoFinalAssinatura: '',
    periodoInicialAprovacao: moment().format('DD/MM/YYYY'),
    periodoFinalAprovacao: moment().format('DD/MM/YYYY'),
    uf: 'TODOS',
    tipoSeguro: 'TODOS',
    operador: 'TODOS',
    statusAnfitriao: 'TODOS',
    perfilFuncionario: 'TODOS',
    statusFuncionario: 'TODOS',
  });

  const [timeCourse, setTimeCourse] = useState({
    seven: false,
    thirty: false,
  });
  const [formSalesReport, setFormSalesReport] = useState({
    nomeEstabelecimento: 'TODOS',
    tipoTransacao: 'TODOS',
  });
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [msgDialog, setMsgDialog] = useState('');

  const [totalVendas, setTotalVendas] = useState(0);

  const refCSV = useRef<any>(null);

  const GetOperators = useApi(endpoints.report.getOperators, ApiMethod.GET);

  const RequestReportDelay = useApi<ReportDelayResponse>(
    endpoints.report.getReportDelay,
    ApiMethod.POST,
  );

  const RequestReportInvoiceIssued = useApi<ReportInvoiceIssuedResponse>(
    endpoints.report.getReportInvoiceIssued,
    ApiMethod.POST,
  );

  const RequestReportIOF = useApi(
    endpoints.report.getReportIOF,
    ApiMethod.POST,
  );

  const RequestReportTransactions = useApi(
    endpoints.report.getReportTransactions,
    ApiMethod.POST,
  );

  const RequestReportSales = useApi(
    endpoints.report.getReportSales,
    ApiMethod.POST,
  );

  const RequestReportCharges = useApi<ReportChargeResponse>(
    endpoints.report.getReportCharges,
    ApiMethod.POST,
  );

  const RequestReportOdonto = useApi(
    endpoints.report.getReportOdonto,
    ApiMethod.POST,
  );

  const RequestReportCancelOdonto = useApi(
    endpoints.report.getReportCancelOdonto,
    ApiMethod.POST,
  );

  const RequestReportBillingOdonto = useApi<ReportBillingOdontoResponse>(
    endpoints.report.getReportBillingOdonto,
    ApiMethod.POST,
  );

  const GetCompanies = useApi(endpoints.consult.searchCompany, ApiMethod.GET);

  const RequestReportFinancialIncome = useApi<ReportFinancialIncomeResponse>(
    endpoints.report.getReportFinancialIncome,
    ApiMethod.POST,
  );

  const RequestReportInvoicesReceived = useApi<ReportInvoicesReceivedResponse>(
    endpoints.report.getReportInvoicesReceived,
    ApiMethod.POST,
  );

  const RequestReportRecipeAgreement = useApi<ReportRecipeAgreementResponse>(
    endpoints.report.getReportRecipeAgreement,
    ApiMethod.POST,
  );

  const RequestReportPositiveRegistration = useApi<ReportPositiveRegistrationResponse>(
    endpoints.report.getReportPositiveUser,
    ApiMethod.POST,
  );

  const RequestReportChangedLimit = useApi<ReportChangedLimitResponse>(
    endpoints.report.getReportChangedLimit,
    ApiMethod.POST,
  );

  const RequestReportFutureInvoices = useApi<ReportFutureInvoiceResponse>(
    endpoints.report.getReportFutureInvoices,
    ApiMethod.POST,
  );

  const RequestReactivatedCustomers = useApi<ReportReactivatedCustomersResponse>(
    endpoints.report.getReportReactivatedCustomers,
    ApiMethod.POST,
  );
  const RequestReportSignedContracts = useApi<any>(
    endpoints.report.getReportSignedContracts,
    ApiMethod.POST,
  );

  const RequestReportTeucardProtege = useApi<any>(
    endpoints.report.getInsuranceReport,
    ApiMethod.POST,
  );

  const RequestReportAnfitriao = useApi<any>(
    endpoints.report.getReportAnfitriao,
    ApiMethod.POST,
  );

  const RequestReportEmployee = useApi(
    endpoints.report.postReportEmployee,
    ApiMethod.POST,
  );

  useEffect(() => {
    (async () => {
      const response = await GetOperators.callApi();

      if (response.status === 200) {
        const data = response.data as GetOperatorsResponse[];

        const getOperadores = data.map(({ nome, id }) => ({
          id,
          value: nome,
        }));

        const getOperadoresName = data.map(({ nome, id }) => ({
          id: nome,
          value: nome,
        }));

        setOperadores(getOperadores);
        setOperadoresName(getOperadoresName);
      } else {
        setMsgDialog(response.mensagem);
        setDialogIsOpen(true);
      }
    })();

    (async () => {
      const response = await GetCompanies.callApi();

      if (response.status === 200) {
        const data = response.data as GetCompaniesResponse[];

        const getCompaniesList = data.map(({ nome }) => ({
          id: nome,
          value: nome,
        }));

        getCompaniesList.push(
          {
            id: 'Transação Digital',
            value: 'Transação Digital',
          },
          {
            id: 'TODOS',
            value: 'Todos',
          },
        );

        setCompanies(getCompaniesList);
      } else {
        setMsgDialog(response.mensagem);
        setDialogIsOpen(true);
      }
    })();
  }, []);

  // Valida Período informado fluxo campo periodo
  function validDate(days: number) {
    const a = moment(form.periodoInicial, 'DD/MM/YYYY');
    const b = moment(form.periodoFinal, 'DD/MM/YYYY');
    const diffDays = b.diff(a, 'days');

    if (diffDays <= days) {
      return true;
    }
    setMsgDialog(`Período informado maior que ${days} dias.`);
    setDialogIsOpen(true);
    return false;
  }

  // Valida Período informado para contratos assinados Rel: 23
  function validDateAssinado(days: number) {
    const a = moment(form.periodoInicialAssinatura, 'DD/MM/YYYY');
    const b = moment(form.periodoFinalAssinatura, 'DD/MM/YYYY');
    const diffDays = b.diff(a, 'days');

    if (diffDays <= days) {
      return true;
    }
    setMsgDialog(
      `Período informado para data de assinatura maior que ${days} dias.`,
    );
    setDialogIsOpen(true);
    return false;
  }

  // Valida Período informado para contratos assinados Rel: 23
  function validDateAprovada(days: number) {
    const a = moment(form.periodoInicialAprovacao, 'DD/MM/YYYY');
    const b = moment(form.periodoFinalAprovacao, 'DD/MM/YYYY');
    const diffDays = b.diff(a, 'days');

    if (diffDays <= days) {
      return true;
    }
    setMsgDialog(
      `Período informado para data de aprovação maior que ${days} dias.`,
    );
    setDialogIsOpen(true);
    return false;
  }

  // SOLICITAR RELATÓRIOS
  const getReport = async (page = 1) => {
    setTotalItems(null);
    setHeader([]);
    setRelatorioData([]);

    // Valida Período informado fluxo campo periodo
    if (timeCourse.seven) {
      if (!validDate(7)) return;
    }

    if (timeCourse.thirty) {
      if (!validDate(30)) return;
    }

    // Valida Período informado para contratos assinados Rel: 23
    if (form.relatorio === '23') {
      if (form.periodoInicialAssinatura) if (!validDateAssinado(30)) return;
      if (form.periodoInicialAprovacao) if (!validDateAprovada(30)) return;
    }

    switch (form.relatorio) {
      case '6':
        getReportTransactions();
        break;
      case '11':
        getReportSales();
        break;
      case '12':
        getRepostIOF();
        break;
      case '13':
        getReportOdonto();
        break;
      case '14':
        getReportCancelOdonto();
        break;
      case '15':
        getReportFinancialIncome(page);
        break;
      case '17':
        getReportRecipeAgreement(page);
        break;
      case '18':
        getReportBillingOdonto(page);
        break;
      case '19':
        getReportPositiveRegistration(page);
        break;
      case '20':
        getChangedLimit(page);
        break;
      case '22':
        getReportReactivatedCustomers(page);
        break;
      case '23':
        getReportSignedContracts(page);
        break;
      case '24':
        getReportCharges(page);
        break;
      case '25':
        getTeucardProtege();
        break;
      case '26':
        getReportAnfitriao(page);
        break;
      case '27':
        getReportEmployee();
        break;
    }
  };

  // DOWNLOAD CSV
  const onClickDownloadCSV = async (currentPage = 1) => {
    let totalPages = 1;
    let response;

    if (currentPage === 1) setDownloadCVSProgress('0%');
    switch (form.relatorio) {
      case '15':
        response = await getReportFinancialIncome(currentPage, 150, true);
        break;
      case '17':
        response = await getReportRecipeAgreement(currentPage, 150, true);
        break;
      case '18':
        response = await getReportBillingOdonto(currentPage, 150, true);
        break;
      case '19':
        response = await getReportPositiveRegistration(currentPage, 150, true);
        break;
      case '20':
        response = await getChangedLimit(currentPage, 150, true);
        break;
      case '22':
        response = await getReportReactivatedCustomers(currentPage, 150, true);
        break;
      case '23':
        response = await getReportSignedContracts(currentPage, 150, true);
        break;
      case '24':
        response = await getReportCharges(currentPage, 150, true);
        break;
      case '26':
        response = await getReportAnfitriao(currentPage, 150, true);
        break;
      case '27':
        response = await getReportEmployee();
        break;
    }

    if (response) {
      const data = response.totalDePaginas ? response : response.data;
      totalPages = data.totalDePaginas;

      const porcent = (currentPage / totalPages) * 100;
      const roundPorcent = Math.ceil(porcent);
      setDownloadCVSProgress(`${roundPorcent}%`);

      if (data.pagina.length) {
        setRelatorioData((prev) => [...prev, ...data.pagina]);
      }
      if (currentPage < totalPages) {
        await onClickDownloadCSV(currentPage + 1);
      }

      setDownloadCVSProgress('');
    }
  };

  const getReportCharges = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload: GetReportChargePayload = {
      data_fechamento_fatura: form.periodoInicial,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportCharges.callApi(payload);

    setHeader(CSV_HEADERS.CHARGES);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportCharges);
      setItems(Models.BodyReportCharges(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getRepostIOF = async () => {
    const payload: GetReportIOFPayload = {
      dataInicial: form.periodoInicial,
      dataFinal: form.periodoFinal,
    };

    const response = await RequestReportIOF.callApi(payload);

    setHeader(CSV_HEADERS.IOF);

    if (response.status === 200) {
      const getData = response.data as ReportIOFResponse;

      setHeaderItems(Models.HeaderRepostIOF);
      setItems(Models.BodyRepostIOF(getData.data));

      setRelatorioData(getData.data);
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportTransactions = async () => {
    const payload: GetReportTransactionPayload = {
      dataInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dataFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    const response = await RequestReportTransactions.callApi(payload);

    setHeader(CSV_HEADERS.TRANSACTIONS);

    if (response.status === 200) {
      const getData = response.data as ReportTransactionResponse;

      setHeaderItems(Models.HeaderReportTransactions);
      setItems(Models.BodyReportTransactions(getData.data));
      setRelatorioData(getData.data);
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportSales = async () => {
    const payload: ReportSalesPayload = {
      ...formSalesReport,
      dataInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      dataFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('DD/MM/YYYY'),
    };

    const response = await RequestReportSales.callApi(payload);

    setHeader(CSV_HEADERS.SALES);

    if (response.status === 200) {
      const getData = response.data as ReportSalesResponse;

      setHeaderItems(Models.HeaderReportSales);
      setItems(Models.BodyReportSales(getData.data));

      setRelatorioData(getData.data);
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportOdonto = async () => {
    const payload = {
      dataInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dataFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      idOperador: form.idAtendente,
      statusCartao: form.statusCartao,
      uf: form.uf,
    };

    const response = await RequestReportOdonto.callApi(payload);

    setHeader(CSV_HEADERS.ODONTO);

    if (response.status === 200) {
      const { vendas } = response.data as ReportResponseOdonto;

      setHeaderItems(Models.HeaderReportOdonto);
      setItems(Models.BodyReportOdonto(vendas));
      setTotalItems(Models.BodyTotalResponseOdonto(vendas));
      setRelatorioData(Models.BodyReportOdontoCSV(vendas));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportCancelOdonto = async () => {
    const payload = {
      dataFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dataInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      idOperador: form.idAtendente,
      statusCartao: form.statusCartao,
      tipoPeriodo: form.tipoPeriodoCancelamentoOdonto,
      uf: form.uf,
    };

    const response = await RequestReportCancelOdonto.callApi(payload);

    setHeader(CSV_HEADERS.CANCEL_ODONTO);

    if (response.status === 200) {
      const { data } = response as ReportResponseCancelOdonto;

      setHeaderItems(Models.HeaderReportCancelOdonto);
      setItems(Models.BodyReportCancelOdonto(data));
      setTotalItems(Models.BodyTotalCancelOdonto(data));
      setRelatorioData(Models.BodyReportCancelOdontoCSV(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportBillingOdonto = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      periodoFaturamentoOdonto: form.periodoFaturamentoOdonto,
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      pagina: page,
      limitePorPagina: limit,
    };

    const response = await RequestReportBillingOdonto.callApi(payload);

    setHeader(CSV_HEADERS.BILLING_ODONTO);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderBillingOdonto);
      setItems(Models.BodyBillingOdonto(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportFinancialIncome = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      limitePorPagina: limit,
      pagina: page,
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      tipo: Number(form.tipoDeEncargo),
    };

    const response = await RequestReportFinancialIncome.callApi(payload);

    setHeader(CSV_HEADERS.FINANCIAL_INCOME);

    if (returnResponse) return response.data;

    const { data } = response.data as ReportFinancialIncomeResponse;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderFinancialIncome);
      setItems(Models.BodyFinancialIncome(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportRecipeAgreement = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ): Promise<ReportRecipeAgreementResponse> => {
    const payload: ReportRecipeAgreementPayload = {
      limitePorPagina: limit,
      pagina: page,
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      tipoData: form.tipoDeData,
    };

    const response = await RequestReportRecipeAgreement.callApi(payload);

    setHeader(CSV_HEADERS.RECIPE_AGREEMENT);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalItems(data.totalItens);
      setTotalPages(data.totalDePaginas);
      setHeaderItems(Models.HeaderRecipeAgreement);
      setItems(Models.BodyRecipeAgreement(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportPositiveRegistration = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ): Promise<ReportPositiveRegistrationResponse> => {
    const payload: ReportPositiveRegistrationPayload = {
      limitePorPagina: limit,
      pagina: page,
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      positivados: form.positiveUser,
    };

    const response = await RequestReportPositiveRegistration.callApi(payload);

    setHeader(CSV_HEADERS.POSITIVE_RESGISTRATION);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.data.totalDePaginas);
      setTotalItems(data.data.totalItens);
      setHeaderItems(Models.HeaderPositiveRegistration);
      setItems(Models.BodyPositiveRegistration(data.data));
    }
  };

  const getChangedLimit = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ): Promise<ReportChangedLimitResponse> => {
    const payload: ReportChangedLimitPayload = {
      limitePorPagina: limit,
      tipoAumento: form.tipoAumento,
      pagina: page,
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    const response = await RequestReportChangedLimit.callApi(payload);

    setHeader(CSV_HEADERS.CHANGED_LIMIT);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderChangedLimit);
      setItems(Models.BodyChangedLimit(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportReactivatedCustomers = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ): Promise<ReportReactivatedCustomersResponse> => {
    const payload: ReportReactivatedCustomersPayload = {
      limitePorPagina: limit,
      ordenacao: form.order,
      pagina: page,
      periodoFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      periodoInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      cartaoDevolvido: form.cartaoDevolvido,
    };

    const response = await RequestReactivatedCustomers.callApi(payload);

    setHeader(CSV_HEADERS.REACTIVATED_CUSTOMERS);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReactivatedCustomers);
      setItems(Models.BodyReactivatedCustomers(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportSignedContracts = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ): Promise<ReportSignedContractsResponse> => {
    let payload = {} as ReportSignedContractsPayload;
    if (
      form.periodoInicialAssinatura !== '' &&
      form.periodoFinalAssinatura !== ''
    ) {
      payload = {
        contratoAssinado: form.contratoAssinado,
        limitePorPagina: limit,
        pagina: page,
        periodoFinalAssinatura:
          form.periodoFinalAssinatura &&
          moment(form.periodoFinalAssinatura, 'DD/MM/YYYY').format(
            'YYYY-MM-DD',
          ),
        periodoInicialAssinatura:
          form.periodoInicialAssinatura &&
          moment(form.periodoInicialAssinatura, 'DD/MM/YYYY').format(
            'YYYY-MM-DD',
          ),
        periodoFinalAprovacao:
          form.periodoFinalAprovacao &&
          moment(form.periodoFinalAprovacao, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        periodoInicialAprovacao:
          form.periodoInicialAprovacao &&
          moment(form.periodoInicialAprovacao, 'DD/MM/YYYY').format(
            'YYYY-MM-DD',
          ),
      };
    } else {
      payload = {
        contratoAssinado: form.contratoAssinado,
        limitePorPagina: limit,
        pagina: page,
        periodoFinalAprovacao:
          form.periodoFinalAprovacao &&
          moment(form.periodoFinalAprovacao, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        periodoInicialAprovacao:
          form.periodoInicialAprovacao &&
          moment(form.periodoInicialAprovacao, 'DD/MM/YYYY').format(
            'YYYY-MM-DD',
          ),
      };
    }

    const response = await RequestReportSignedContracts.callApi(payload);

    setHeader(CSV_HEADERS.SIGNED_CONTRACTS);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setTotalPages(response.data.totalDePaginas);
      setTotalItems(response.data.totalItens);
      setHeaderItems(Models.HeaderSignedContracts);
      setItems(Models.BodySignedContracts(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getTeucardProtege = async (
    returnResponse = false,
  ): Promise<ReportTeucardProtegeResponse> => {
    const payload: ReportTeucardProtegePayload = {
      dataFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dataInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      operador: form.atendenteName,
      uf: form.uf,
      tipoSeguro: form.tipoSeguro,
    };

    const response = await RequestReportTeucardProtege.callApi(payload);

    setHeader(CSV_HEADERS.TEUCARD_PROTEGE);

    if (returnResponse) return response.data;
    const { data } = response;

    if (response.status === 200) {
      setHeaderItems(Models.HeaderTeucardProtege);
      setItems(Models.BodyTeucardProtege(data));
      setRelatorioData(Models.BodyTeucardProtegeCSV(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportAnfitriao = async (
    page = 1,
    limit = 10,
    returnResponse = false,
  ) => {
    const payload = {
      dataCadastroInicial:
        form.periodoInicial &&
        moment(form.periodoInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dataCadastroFinal:
        form.periodoFinal &&
        moment(form.periodoFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      status: form.statusAnfitriao,
      limitePorPagina: limit,
      pagina: page,
    };

    const response = await RequestReportAnfitriao.callApi(payload);

    setHeader(CSV_HEADERS.ANFITRIAO);

    if (returnResponse) return response.data;

    const { data } = response;

    if (response.status === 200) {
      setTotalPages(data.totalDePaginas);
      setTotalItems(data.totalItens);
      setHeaderItems(Models.HeaderReportAnfitriao);
      setItems(Models.BodyReportAnfitriao(data));
    } else {
      setMsgDialog(response.mensagem);
      setDialogIsOpen(true);
    }
  };

  const getReportEmployee = async (returnResponse = false) => {
    const payload = {
      status: form.statusFuncionario,
      perfil: form.perfilFuncionario,
    };

    try {
      const response = await RequestReportEmployee.callApi(payload);

      setHeader(CSV_HEADERS.EMPLOYEE);

      if (returnResponse) return response.data;

      const { data } = response;

      if (response.status === 200) {
        setHeaderItems(Models.HeaderReportEmployee);
        setItems(Models.BodyReportEmployee(data));
        setRelatorioData(Models.BodyReportEmployeeCSV(data));
      } else {
        setMsgDialog(response.mensagem);
        setDialogIsOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Verificar Status
  const checkSuccess = () => {
    if (
      RequestReportDelay.error ||
      RequestReportDelay.status === 400 ||
      RequestReportDelay.status === 500 ||
      RequestReportSales.error ||
      RequestReportSales.status === 400 ||
      RequestReportSales.status === 500 ||
      RequestReportInvoiceIssued.error ||
      RequestReportInvoiceIssued.status === 400 ||
      RequestReportInvoiceIssued.status === 500 ||
      RequestReportTransactions.error ||
      RequestReportTransactions.status === 404 ||
      RequestReportTransactions.status === 500 ||
      RequestReportIOF.error ||
      RequestReportIOF.status === 404 ||
      RequestReportIOF.status === 500 ||
      RequestReportCharges.error ||
      RequestReportCharges.status === 404 ||
      RequestReportCharges.status === 500 ||
      RequestReportOdonto.error ||
      RequestReportOdonto.status === 404 ||
      RequestReportOdonto.status === 500 ||
      RequestReportCancelOdonto.error ||
      RequestReportCancelOdonto.status === 404 ||
      RequestReportCancelOdonto.status === 500 ||
      RequestReportBillingOdonto.error ||
      RequestReportBillingOdonto.status === 404 ||
      RequestReportBillingOdonto.status === 500 ||
      RequestReportFinancialIncome.error ||
      RequestReportFinancialIncome.status === 404 ||
      RequestReportFinancialIncome.status === 500 ||
      RequestReportInvoicesReceived.error ||
      RequestReportInvoicesReceived.status === 404 ||
      RequestReportInvoicesReceived.status === 500 ||
      RequestReportRecipeAgreement.error ||
      RequestReportRecipeAgreement.status === 404 ||
      RequestReportRecipeAgreement.status === 500 ||
      RequestReportPositiveRegistration.error ||
      RequestReportPositiveRegistration.status === 404 ||
      RequestReportPositiveRegistration.status === 500 ||
      RequestReportChangedLimit.error ||
      RequestReportChangedLimit.status === 404 ||
      RequestReportChangedLimit.status === 500 ||
      RequestReportFutureInvoices.error ||
      RequestReportFutureInvoices.status === 404 ||
      RequestReportFutureInvoices.status === 500 ||
      RequestReactivatedCustomers.error ||
      RequestReactivatedCustomers.status === 404 ||
      RequestReactivatedCustomers.status === 500 ||
      RequestReportSignedContracts.error ||
      RequestReportSignedContracts.status === 404 ||
      RequestReportSignedContracts.status === 500 ||
      RequestReportTeucardProtege.error ||
      RequestReportTeucardProtege.status === 404 ||
      RequestReportTeucardProtege.status === 500
    ) {
      return true;
    }

    return false;
  };

  // Verificar mudanças no formulário
  const handleChangeForm = (e: string) => {
    setItems([]);
    setHeader([]);
    setRelatorioData([]);
    setTotalVendas(0);

    setForm({ ...form, relatorio: e });

    switch (e) {
      case '1':
        setTimeCourse({ seven: true, thirty: false });
        break;
      case '2':
        setTimeCourse({ seven: false, thirty: true });
        break;
      case '3':
        setTimeCourse({ seven: true, thirty: false });
        break;
      case '6':
        setTimeCourse({ seven: false, thirty: true });
        break;
      case '11':
        setTimeCourse({ seven: false, thirty: true });
        break;
      case '14':
        setTimeCourse({ seven: true, thirty: false });
        break;
      case '23':
        setTimeCourse({ seven: false, thirty: true });
        break;
      case '24':
        setTimeCourse({ seven: false, thirty: true });
        break;
      default:
        setTimeCourse({ seven: false, thirty: false });
        break;
    }
  };

  return (
    <>
      <Loading
        status={
          GetOperators.loading ||
          GetCompanies.loading ||
          RequestReportDelay.loading ||
          RequestReportSales.loading ||
          RequestReportInvoiceIssued.loading ||
          RequestReportIOF.loading ||
          RequestReportCharges.loading ||
          RequestReportFinancialIncome.loading ||
          RequestReportTransactions.loading ||
          RequestReportOdonto.loading ||
          RequestReportInvoicesReceived.loading ||
          RequestReportRecipeAgreement.loading ||
          RequestReportBillingOdonto.loading ||
          RequestReportInvoicesReceived.loading ||
          RequestReportPositiveRegistration.loading ||
          RequestReportChangedLimit.loading ||
          RequestReportFutureInvoices.loading ||
          RequestReactivatedCustomers.loading ||
          RequestReportSignedContracts.loading ||
          RequestReportCancelOdonto.loading ||
          RequestReportTeucardProtege.loading ||
          RequestReportAnfitriao.loading ||
          RequestReportEmployee.loading
        }
        text={downloadCVSProgress}
      />
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message={msgDialog}
      />

      <Container>
        <St.BoxCard>
          <St.SectionReport>
            <Select
              label="Tipo de relatório"
              value={form.relatorio}
              options={REPORT_TYPES}
              onChange={(e) => handleChangeForm(e)}
            />

            {form.relatorio === '15' && (
              <Select
                label="Tipo de Encargo"
                value={form.tipoDeEncargo}
                options={[
                  { id: '', value: 'Todos' },
                  { id: '1', value: 'Anuidade' },
                  { id: '2', value: 'Juros' },
                  { id: '3', value: 'Multa' },
                  { id: '4', value: 'Rotativo' },
                  { id: '5', value: 'Juros de Financiamento de Faturas' },
                  { id: '6', value: 'Tarifa de Cobrança' },
                  { id: '7', value: 'Seguros' },
                  { id: '8', value: 'Odonto' },
                  { id: '9', value: 'Cuida' },
                  { id: '10', value: 'TAC de saque a vista' },
                  { id: '11', value: 'Juros de saque a vista' },
                ]}
                onChange={(e) => setForm({ ...form, tipoDeEncargo: e })}
              />
            )}

            {form.relatorio !== '6' &&
              form.relatorio !== '11' &&
              form.relatorio !== '12' &&
              form.relatorio !== '15' &&
              form.relatorio !== '17' &&
              form.relatorio !== '18' &&
              form.relatorio !== '19' &&
              form.relatorio !== '20' &&
              form.relatorio !== '22' &&
              form.relatorio !== '23' &&
              form.relatorio !== '25' &&
              form.relatorio !== '26' &&
              form.relatorio !== '27' && (
                <Select
                  label="Operador(a) de Venda"
                  value={form.idAtendente}
                  options={[
                    { id: 'TODOS', value: 'Todos os operadores' },
                    { id: '6041102c1b705c0007d62e81', value: 'Web' },
                    ...operadores,
                  ]}
                  onChange={(e) => setForm({ ...form, idAtendente: e })}
                />
              )}

            {form.relatorio === '25' && (
              <Select
                label="Operador(a) de Venda"
                value={form.atendenteName}
                options={[
                  { id: 'TODOS', value: 'Todos os operadores' },
                  { id: 'Web', value: 'Web' },
                  ...operadoresName,
                ]}
                onChange={(e) => setForm({ ...form, atendenteName: e })}
              />
            )}

            {form.relatorio !== '1' &&
              form.relatorio !== '2' &&
              form.relatorio !== '3' &&
              form.relatorio !== '6' &&
              form.relatorio !== '11' &&
              form.relatorio !== '12' &&
              form.relatorio !== '13' &&
              form.relatorio !== '14' &&
              form.relatorio !== '15' &&
              form.relatorio !== '17' &&
              form.relatorio !== '18' &&
              form.relatorio !== '19' &&
              form.relatorio !== '20' &&
              form.relatorio !== '22' &&
              form.relatorio !== '23' &&
              form.relatorio !== '24' &&
              form.relatorio !== '25' &&
              form.relatorio !== '26' &&
              form.relatorio !== '27' && (
                <Select
                  label="Origem"
                  value={form.origem}
                  options={[
                    { id: 'TODOS', value: 'Todas as origens' },
                    { id: 'LOJA', value: 'Loja' },
                    { id: 'SITE', value: 'Site' },
                    { id: 'APP', value: 'App' },
                    { id: 'TOTEM', value: 'Totem' },
                  ]}
                  onChange={(e) => setForm({ ...form, origem: e })}
                />
              )}

            {/* cadastradas / aprovadas / reprovadas */}
            {['1', '2', '3'].includes(form.relatorio) && (
              <Select
                label="Origem"
                value={form.origem}
                options={[
                  { id: 'TODOS', value: 'Todas as origens' },
                  { id: 'LOJA', value: 'Loja' },
                  { id: 'SITE', value: 'Site' },
                  { id: 'APP', value: 'App' },
                  { id: 'TOTEM', value: 'Totem' },
                ]}
                onChange={(e) => setForm({ ...form, origem: e })}
              />
            )}

            {['13', '14'].includes(form.relatorio) && (
              <Select
                label="Status Cartão"
                value={form.statusCartao}
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: 'ATIVO', value: 'Ativo' },
                  { id: 'INATIVO', value: 'Inativo' },
                  { id: 'CANCELADO', value: 'Cancelado' },
                  { id: 'INADIMPLENTE', value: 'Inadimplente' },
                  { id: 'COBRANCA', value: 'Cobrança' },
                ]}
                onChange={(e) => setForm({ ...form, statusCartao: e })}
              />
            )}

            {/* relatórios: registradas / aprovadas / aprovadas P1 / vendas Odonto */}
            {['1', '2', '8', '13', '14', '25'].includes(form.relatorio) && (
              <Select
                label="UF"
                value={form.uf}
                options={UFs}
                onChange={(e) => setForm({ ...form, uf: e })}
              />
            )}

            {form.relatorio === '20' && (
              <Select
                label="Meios de Aumento"
                value={form.tipoAumento}
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: 'AUTOMATICO', value: 'Automático' },
                  { id: 'MANUAL', value: 'Manual' },
                  { id: 'CAMPANHA', value: 'Campanha' },
                ]}
                onChange={(e) => setForm({ ...form, tipoAumento: e })}
              />
            )}

            {form.relatorio === '17' && (
              <Select
                label="Tipo de Data"
                value={form.tipoDeData}
                options={[
                  { id: 'DATA_INCLUSAO', value: 'Data Inclusão' },
                  { id: 'DATA_DECISAO', value: 'Data Decisão' },
                ]}
                onChange={(e) => setForm({ ...form, tipoDeData: e })}
              />
            )}

            {form.relatorio === '25' && (
              <Select
                label="Tipo de seguro"
                value={form.tipoSeguro}
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  {
                    id: 'PRESTAMISTA',
                    value: 'Prestamista',
                  },
                  { id: 'BOLSA_PROTEGIDA', value: 'Bolsa Protegida' },
                  { id: 'PERDA_RENDA', value: 'Perda de Renda' },
                ]}
                onChange={(e) => setForm({ ...form, tipoSeguro: e })}
              />
            )}

            {form.relatorio === '18' && (
              <Select
                label="Tipo de Período"
                value={form.periodoFaturamentoOdonto}
                options={[
                  { id: 'DT_ADESAO', value: 'Data de Adesão' },
                  { id: 'DT_PAGAMENTO', value: 'Data de Pagamento' },
                ]}
                onChange={(e) =>
                  setForm({ ...form, periodoFaturamentoOdonto: e })
                }
              />
            )}

            {['14'].includes(form.relatorio) && (
              <Select
                label="Tipo de Período"
                value={form.tipoPeriodoCancelamentoOdonto}
                options={[
                  { id: 'DT_VENDA', value: 'Data de Venda' },
                  { id: 'DT_CANCELAMENTO', value: 'Data de Cancelamento' },
                ]}
                onChange={(e) =>
                  setForm({ ...form, tipoPeriodoCancelamentoOdonto: e })
                }
              />
            )}

            {form.relatorio === '22' && (
              <Select
                label="Cartão devolvido?"
                value={form.cartaoDevolvido}
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: 'SIM', value: 'Sim' },
                  { id: 'NAO', value: 'Não' },
                ]}
                onChange={(e) => setForm({ ...form, cartaoDevolvido: e })}
              />
            )}

            {form.relatorio === '23' && (
              <>
                <Select
                  label="Contrato assinado?"
                  value={form.contratoAssinado}
                  options={[
                    { id: 'TODOS', value: 'Todos' },
                    { id: 'SIM', value: 'Sim' },
                    { id: 'NAO', value: 'Não' },
                  ]}
                  onChange={(e) => setForm({ ...form, contratoAssinado: e })}
                />
                <ContainerFlex>
                  <St.SpanLabel>Período de assinatura</St.SpanLabel>

                  <ContainerFlex row space>
                    <Input
                      label="De"
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      value={form.periodoInicialAssinatura}
                      onChange={(e) => {
                        return setForm({
                          ...form,
                          periodoInicialAssinatura: e.target.value.masked,
                        });
                      }}
                      mask={MaskTypes.DATE}
                    />

                    <St.DivDivider />
                    <Input
                      label="Até"
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      value={form.periodoFinalAssinatura}
                      onChange={(e) => {
                        return setForm({
                          ...form,
                          periodoFinalAssinatura: e.target.value.masked,
                        });
                      }}
                      mask={MaskTypes.DATE}
                    />
                  </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex>
                  <St.SpanLabel>Período de aprovação</St.SpanLabel>

                  <ContainerFlex row space>
                    <Input
                      label="De"
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      value={form.periodoInicialAprovacao}
                      onChange={(e) => {
                        return setForm({
                          ...form,
                          periodoInicialAprovacao: e.target.value.masked,
                        });
                      }}
                      mask={MaskTypes.DATE}
                    />

                    <St.DivDivider />
                    <Input
                      label="Até"
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      value={form.periodoFinalAprovacao}
                      onChange={(e) => {
                        return setForm({
                          ...form,
                          periodoFinalAprovacao: e.target.value.masked,
                        });
                      }}
                      mask={MaskTypes.DATE}
                    />
                  </ContainerFlex>
                </ContainerFlex>
                {timeCourse.seven && (
                  <St.SpanLabelFeed>
                    * O período de busca deve ser menor ou igual a 7 dias.
                  </St.SpanLabelFeed>
                )}
                {timeCourse.thirty && (
                  <St.SpanLabelFeed>
                    * O período de busca deve ser menor ou igual a 30 dias.
                  </St.SpanLabelFeed>
                )}
              </>
            )}

            {form.relatorio === '19' && (
              <>
                <Select
                  label="Ciente positivado"
                  value={form.positiveUser}
                  options={[
                    { id: 'SIM', value: 'Sim' },
                    { id: 'NÃO', value: 'Não' },
                  ]}
                  onChange={(e) => {
                    return setForm({
                      ...form,
                      positiveUser: e,
                    });
                  }}
                />
              </>
            )}

            {['11'].includes(form.relatorio) && (
              <>
                <AutoCompleteSelect
                  label="Estabelecimento"
                  value={formSalesReport.nomeEstabelecimento}
                  options={companies}
                  onChange={(e) => {
                    return setFormSalesReport({
                      ...formSalesReport,
                      nomeEstabelecimento: e?.value,
                    });
                  }}
                />
                <Select
                  label="Tipo de transação"
                  value={formSalesReport.tipoTransacao}
                  options={[
                    { id: 'TODOS', value: 'Todos' },
                    { id: 'AVISTA', value: 'À vista' },
                    { id: 'PRAZO', value: 'A prazo' },
                  ]}
                  onChange={(e) => {
                    return setFormSalesReport({
                      ...formSalesReport,
                      tipoTransacao: e,
                    });
                  }}
                />
              </>
            )}

            {['26'].includes(form.relatorio) && (
              <Select
                label="Status"
                value={form.statusAnfitriao}
                options={[
                  { id: 'TODOS', value: 'Todos' },
                  { id: 'ATIVO', value: 'Ativo' },
                  { id: 'EXPIRADO', value: 'Expirado' },
                ]}
                onChange={(e) => setForm({ ...form, statusAnfitriao: e })}
              />
            )}

            {['27'].includes(form.relatorio) && (
              <>
                <Select
                  label="Perfis"
                  value={form.perfilFuncionario}
                  options={[
                    { id: 'TODOS', value: 'Todos' },
                    { id: 'ADM', value: 'Administração' },
                    { id: 'OPR', value: 'Operador' },
                    { id: 'RET', value: 'Retaguarda' },
                    { id: 'MDC', value: 'Mesa de Crédito' },
                    { id: 'SUP', value: 'Supervisor' },
                    { id: 'CXA', value: 'Caixa' },
                    { id: 'ASSESSORIA', value: 'Assessoria' },
                  ]}
                  onChange={(e) => setForm({ ...form, perfilFuncionario: e })}
                />
                <Select
                  label="Status"
                  value={form.statusFuncionario}
                  options={[
                    { id: 'TODOS', value: 'Todos' },
                    { id: 'ATIVO', value: 'Ativo' },
                    { id: 'INATIVO', value: 'Inativo' },
                  ]}
                  onChange={(e) => setForm({ ...form, statusFuncionario: e })}
                />
              </>
            )}

            {form.relatorio !== '1' &&
              form.relatorio !== '2' &&
              form.relatorio !== '3' &&
              form.relatorio !== '8' &&
              form.relatorio !== '9' &&
              form.relatorio !== '23' &&
              form.relatorio !== '27' && (
                <ContainerFlex>
                  <St.SpanLabel>Período</St.SpanLabel>

                  <ContainerFlex row space>
                    <Input
                      label="De"
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      value={form.periodoInicial}
                      onChange={(e) => {
                        return setForm({
                          ...form,
                          periodoInicial: e.target.value.masked,
                        });
                      }}
                      mask={MaskTypes.DATE}
                    />

                    <St.DivDivider />
                    <Input
                      label="Até"
                      placeholder="DD/MM/YYYY"
                      maxLength={10}
                      value={form.periodoFinal}
                      onChange={(e) => {
                        return setForm({
                          ...form,
                          periodoFinal: e.target.value.masked,
                        });
                      }}
                      mask={MaskTypes.DATE}
                    />
                  </ContainerFlex>
                  {timeCourse.seven && (
                    <St.SpanLabelFeed>
                      * O período de busca deve ser menor ou igual a 7 dias.
                    </St.SpanLabelFeed>
                  )}
                  {timeCourse.thirty && (
                    <St.SpanLabelFeed>
                      * O período de busca deve ser menor ou igual a 30 dias.
                    </St.SpanLabelFeed>
                  )}
                </ContainerFlex>
              )}

            {form.relatorio === '24' && (
              <ContainerFlex>
                <St.SpanLabel>Período</St.SpanLabel>

                <ContainerFlex row space>
                  <Input
                    placeholder="DD/MM/YYYY"
                    maxLength={10}
                    value={form.periodoInicial}
                    onChange={(e) => {
                      return setForm({
                        ...form,
                        periodoInicial: e.target.value.masked,
                      });
                    }}
                    mask={MaskTypes.DATE}
                  />
                </ContainerFlex>
              </ContainerFlex>
            )}

            <ContainerFlex>
              <ContainerFlex flex="0.2" marginTop={24} maxWidth={10}>
                <Button
                  fitContent
                  disabled={
                    RequestReportDelay.loading ||
                    RequestReportSales.loading ||
                    RequestReportInvoiceIssued.loading ||
                    RequestReportIOF.loading ||
                    RequestReportCharges.loading
                  }
                  onClick={() => {
                    getReport();
                    setTotalPages(0);
                  }}
                >
                  <span>Consultar</span>
                </Button>
              </ContainerFlex>
            </ContainerFlex>
          </St.SectionReport>
        </St.BoxCard>

        {items.length > 0 && (
          <St.BoxCard>
            {/* <St.BoxCardHeader></St.BoxCardHeader> */}
            <ContainerFlex>
              {items.length > 0 && (
                <>
                  <ContainerFlex row space center padding={24}>
                    <ContainerFlex>
                      <St.Results>
                        Resultados retornados:&nbsp;
                        {totalItems || items.length}
                      </St.Results>
                      {totalVendas > 0 && (
                        <St.Results>
                          Total de vendas retornadas:&nbsp;
                          {totalVendas}
                        </St.Results>
                      )}
                    </ContainerFlex>

                    {form.relatorio !== '19' && (
                      <Button
                        onClick={() => {
                          if (refCSV.current) {
                            onClickDownloadCSV().then(() =>
                              refCSV.current.link.click(),
                            );
                          }
                        }}
                        disabled={
                          RequestReportDelay.loading ||
                          RequestReportSales.loading ||
                          RequestReportInvoiceIssued.loading ||
                          RequestReportIOF.loading ||
                          RequestReportCharges.loading
                        }
                      >
                        Download relatório
                      </Button>
                    )}
                    {form.relatorio === '19' && (
                      <Button
                        onClick={() => {
                          if (refCSV.current) {
                            onClickDownloadCSV().then(() =>
                              refCSV.current.link.click(),
                            );
                          }
                        }}
                        disabled={moment(
                          form.periodoInicial,
                          'YYYY-MM-DD',
                        ).isBefore(
                          moment(form.periodoFinal, 'YYYY-MM-DD'),
                          'day',
                        )}
                      >
                        Download relatório
                      </Button>
                    )}
                  </ContainerFlex>

                  <ContainerFlex>
                    <TableList customerHeader={headerItems} list={items} />

                    {form.relatorio !== '6' &&
                      form.relatorio !== '11' &&
                      form.relatorio !== '12' &&
                      form.relatorio !== '13' &&
                      form.relatorio !== '25' &&
                      form.relatorio !== '27' &&
                      items.length > 0 && (
                        <ContainerFlex row horizontal center marginTop={20}>
                          <Pagination
                            totalPages={totalPages}
                            onCurrentPageChange={getReport}
                          />
                        </ContainerFlex>
                      )}

                    <CSVLink
                      ref={refCSV}
                      data={relatorioData}
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                      }}
                      headers={header}
                      filename="relatorio.csv"
                      target="_blank"
                    />
                  </ContainerFlex>
                </>
              )}

              {checkSuccess() ||
                (items.length <= 0 && (
                  <ContainerFlex center>
                    <TitleCategory>Nenhum resultado encontrado</TitleCategory>
                  </ContainerFlex>
                ))}

              <CSVLink
                ref={refCSV}
                data={relatorioData}
                style={{
                  color: 'inherit',
                  textDecoration: 'none',
                }}
                headers={header}
                filename="relatorio.csv"
                target="_blank"
              />
            </ContainerFlex>
          </St.BoxCard>
        )}
      </Container>
    </>
  );
};

export default Report;
