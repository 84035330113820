import {
  HeaderReportWithdrawBilling,
  BodyReportWithdrawBilling,
  BodyReportWithdrawBillingCSV,
} from './modelReportWithdrawBilling';
import {
  HeaderReportLoanSeeker,
  BodyReportLoanSeeker,
  BodyReportLoanSeekerCSV,
} from './modelReportLoanSeeker';
import {
  BodyProposalsRegistered,
  HeaderProposalsRegistered,
} from './modelsProposalsRegistered';
import {
  HeaderReportEmployee,
  BodyReportEmployee,
  BodyReportEmployeeCSV,
} from './modelsReportEmployee';
import {
  BodyReportApproved,
  HeaderReportAproved,
} from './modelsReportApproved';
import {
  HeaderReportDisapproved,
  BodyReportDisapproved,
} from './modelsReportDisapprove';
import { HeaderInactiveCards, BodyInactiveCards } from './modelsInactiveCards';
import { HeaderUnlockedCards, BodyUnlockedCards } from './modelsUnlockedCards';
import {
  HeaderUpgradePlatinumCard,
  BodyUpgradePlatinumCard,
  BodyUpgradePlatinumCardCSV,
} from './modelsUpgradePlatinumCard';
import {
  HeaderReportTransactions,
  BodyReportTransactions,
} from './modelsReportTransactions';
import {
  HeaderReportInvoiceIssued,
  BodyReportInvoiceIssued,
} from './modelsReportInvoiceIssued';
import {
  HeaderReportProposalP1,
  BodyReportProposalP1,
} from './modelsApprovedP1';
import { BodyDisapprovedP1, HeaderDisapprovedP1 } from './modelsDisapprovedP1';
import { HeaderInvoiceDelay, BodyInvoiceDelay } from './modelsInvoiceDelay';
import { HeaderReportSales, BodyReportSales } from './modelsReportSales';
import { HeaderRepostIOF, BodyRepostIOF } from './modelsRepostIOF';
import {
  HeaderReportOdonto,
  BodyReportOdonto,
  BodyReportOdontoCSV,
  BodyTotalResponseOdonto,
} from './modelsReportOdonto';
import {
  HeaderReportCuida,
  BodyReportCuida,
  BodyReportCuidaCSV,
  BodyTotalCuida,
} from './modelsReportCuida';
import {
  HeaderReportCancelCuida,
  BodyReportCancelCuida,
  BodyReportCancelCuidaCSV,
  BodyTotalCancelCuida,
} from './modelsReportCancelCuida';
import {
  HeaderReportEventAccountCancellation,
  BodyReportEventAccountCancellation,
  BodyReportEventAccountCancellationCSV,
} from './modelsAccountCancellation';
import {
  HeaderReportCancelOdonto,
  BodyReportCancelOdonto,
  BodyReportCancelOdontoCSV,
  BodyTotalCancelOdonto,
} from './modelsReportCancelOdonto';
import { HeaderReportCharges, BodyReportCharges } from './modelsReportCharges';
import {
  HeaderFinancialIncome,
  BodyFinancialIncome,
} from './modelsFinancialIncome';
import {
  HeaderInvoiceReceived,
  BodyInvoiceReceived,
} from './modelsInvoiceReceived';
import {
  HeaderRecipeAgreement,
  BodyRecipeAgreement,
} from './modelsRecipeAgreement';
import { BodyBillingOdonto, HeaderBillingOdonto } from './modelsBillingOdonto';
import {
  HeaderPositiveRegistration,
  BodyPositiveRegistration,
} from './modelsPositiveRegistration';
import { HeaderChangedLimit, BodyChangedLimit } from './modelsChangedLimit';
import {
  HeaderFutureInvoices,
  BodyFutureInvoices,
} from './modelsFutureInvoices';
import {
  HeaderReactivatedCustomers,
  BodyReactivatedCustomers,
} from './modelsReactivatedCustomers';
import {
  HeaderSignedContracts,
  BodySignedContracts,
} from './modelsSignedContracts';
import {
  HeaderTeucardProtege,
  BodyTeucardProtege,
  BodyTeucardProtegeCSV,
  BodyTotalTeucardProtege,
} from './modelsReportTeucardProtege';
import {
  HeaderReportAdesoes,
  BodyReportAdesoes,
  BodyReportAdesoesCSV,
} from './modelsReportAdesoes';
import {
  HeaderReportCancellationPlatinum,
  BodyReportCancellationPlatinum,
  BodyReportCancellationPlatinumCSV,
} from './modelsReportCancellationPlatinum';
import {
  HeaderReportCancelSafe,
  BodyReportCancelSafe,
  BodyReportCancelSafeCSV,
  BodyTotalCancelSafe,
} from './modelsReportCancelSafe';
import {
  HeaderReportVendas,
  BodyReportVendas,
  BodyReportVendasCSV,
} from './modelsReportVendas';
import {
  HeaderReportVendasLeads,
  BodyReportVendasLeads,
  BodyReportVendasLeadsCSV,
} from './modelsLeadsSales';

import {
  HeaderReportWalletTransactions,
  BodyReportWalletTransactions,
  BodyReportWalletTransactionsCSV,
} from './modelsReportWalletTrasactions';

import {
  HeaderReportWithDraw,
  BodyReportWithDraw,
  BodyReportWithDrawCSV,
} from './modelReportWithDraw';

import {
  HeaderCashBackZeuss,
  BodyCashBackZeuss,
  BodyReportCashBackZeussCSV,
} from './modelsReportCashBackZeuss';

import {
  HeaderManuallyInvoices,
  BodyManuallyInvoices,
  BodyReportManuallyCSV,
} from './modelsManuallyInvoices';

import {
  HeaderReportAnfitriao,
  BodyReportAnfitriao,
} from './modelsReportAnfitriao';

const Models = {
  HeaderProposalsRegistered,
  BodyProposalsRegistered,
  HeaderReportAproved,
  BodyReportApproved,
  HeaderReportDisapproved,
  BodyReportDisapproved,
  HeaderInactiveCards,
  BodyInactiveCards,
  HeaderUnlockedCards,
  BodyUpgradePlatinumCard,
  BodyUpgradePlatinumCardCSV,
  HeaderUpgradePlatinumCard,
  BodyUnlockedCards,
  HeaderReportTransactions,
  BodyReportTransactions,
  HeaderReportInvoiceIssued,
  BodyReportInvoiceIssued,
  HeaderReportProposalP1,
  BodyReportProposalP1,
  HeaderReportEventAccountCancellation,
  BodyReportEventAccountCancellation,
  BodyReportEventAccountCancellationCSV,
  HeaderDisapprovedP1,
  BodyDisapprovedP1,
  HeaderInvoiceDelay,
  BodyInvoiceDelay,
  HeaderReportSales,
  BodyReportSales,
  HeaderRepostIOF,
  BodyRepostIOF,
  HeaderReportOdonto,
  BodyReportOdonto,
  BodyReportOdontoCSV,
  BodyTotalResponseOdonto,
  HeaderReportCancelOdonto,
  BodyReportCancelOdonto,
  BodyReportCancelOdontoCSV,
  BodyTotalCancelOdonto,
  HeaderReportCuida,
  BodyReportCuida,
  BodyReportCuidaCSV,
  BodyTotalCuida,
  HeaderReportCancelCuida,
  BodyReportCancelCuida,
  BodyReportCancelCuidaCSV,
  HeaderReportEmployee,
  BodyReportEmployee,
  BodyReportEmployeeCSV,
  BodyTotalCancelCuida,
  HeaderReportCharges,
  BodyReportCharges,
  HeaderFinancialIncome,
  BodyFinancialIncome,
  HeaderInvoiceReceived,
  BodyInvoiceReceived,
  HeaderRecipeAgreement,
  BodyRecipeAgreement,
  HeaderCashBackZeuss,
  BodyCashBackZeuss,
  BodyReportCashBackZeussCSV,
  HeaderBillingOdonto,
  BodyBillingOdonto,
  HeaderPositiveRegistration,
  BodyPositiveRegistration,
  HeaderChangedLimit,
  BodyChangedLimit,
  HeaderFutureInvoices,
  BodyFutureInvoices,
  HeaderReactivatedCustomers,
  BodyReactivatedCustomers,
  HeaderSignedContracts,
  BodySignedContracts,
  HeaderTeucardProtege,
  BodyTeucardProtege,
  BodyTeucardProtegeCSV,
  HeaderReportCancellationPlatinum,
  BodyReportCancellationPlatinum,
  BodyReportCancellationPlatinumCSV,
  HeaderReportVendasLeads,
  BodyReportVendasLeads,
  BodyReportVendasLeadsCSV,
  BodyTotalTeucardProtege,
  HeaderReportCancelSafe,
  BodyReportCancelSafe,
  BodyReportCancelSafeCSV,
  BodyTotalCancelSafe,
  HeaderReportAdesoes,
  BodyReportAdesoes,
  BodyReportAdesoesCSV,
  HeaderReportVendas,
  BodyReportVendas,
  BodyReportVendasCSV,
  HeaderReportWalletTransactions,
  BodyReportWalletTransactions,
  BodyReportWalletTransactionsCSV,
  HeaderReportWithDraw,
  BodyReportWithDraw,
  BodyReportWithDrawCSV,
  HeaderReportLoanSeeker,
  BodyReportLoanSeeker,
  BodyReportLoanSeekerCSV,
  HeaderReportWithdrawBilling,
  BodyReportWithdrawBilling,
  BodyReportWithdrawBillingCSV,
  HeaderManuallyInvoices,
  BodyManuallyInvoices,
  BodyReportManuallyCSV,
  HeaderReportAnfitriao,
  BodyReportAnfitriao,
};

export default Models;
